import { put, takeLatest } from "@redux-saga/core/effects";
import { errorMessage } from "../../common/util/Helpers";
import { fetchCatAssetsReq } from "./dashboardCrud";
import dashboardSlice from "./dashboardSlice";


/**
 * Saga Dashboard Middlewares (Side Effects)
 */

export default function* dashboardSaga() {

    /* Dashboard Data Fetched Side Effect */

    yield takeLatest("dashboard/dashboardDataFetched", function* dashboardDataFetchedSaga({payload}) {
        // Fetch Categories Assets
        try {
            const catIds = payload.instrument?.themes?.map(item => item.id) ?? [];
            if (catIds?.length > 0){
                const {data: {data}} = yield fetchCatAssetsReq(payload.provider, catIds);
                yield put(dashboardSlice.actions.catAssetsFetched(data));
            }
        }
        catch(err) {
            yield put(dashboardSlice.actions.dashboardNotification({ notification: {type: "error", message: errorMessage(err)}}));
        }
    });

}
