import { createSlice } from "@reduxjs/toolkit";


const initialFreeSharesState = {
    isLoading: false,
    notification: null,
    referralLog: null,
    receivedStocksListEndCheck: false,
};

export default createSlice({
    name: "freeShares",
    initialState: initialFreeSharesState,
    reducers: {
        // FreeShares Response Notification
        freeSharesNotification: (state, action) => {
            state.isLoading = false;
            state.notification = action.payload.notification;
        },
        // FreeShares Loading
        isLoading: (state, _) => {
            state.isLoading = true;
            state.notification = null
        },

        /*****
         *  FreeShares Reducers
         */

        // FetchReferralLog
        referralLogFetched: (state, action) => {
            state.isLoading = false;
            state.referralLog = action.payload.promotion.retrieveReferralLog;
        },

    }
});