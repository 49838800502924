import {createSlice} from "@reduxjs/toolkit";

const initialMarketInsightState = {
  isLoading: false,
  notification: null,
  urls: null,
};

export default createSlice({
  name: "marketInsight",
  initialState: initialMarketInsightState,
  reducers: {
    // Market Insight Response Notification
    marketInsightNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
    // Market Insight Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },

    
    /*****
     *  Market Insight Reducers
     */

    // fetchMarketInsightUrl
    marketInsightUrlFetched: (state, action) => {
      state.isLoading = false;
      state.urls = {technical: action.payload.marketInsight?.technicalInsightUrl, analysis: action.payload.marketInsight?.valueAnalyzerUrl};
    },
  }
});