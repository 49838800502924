import {createSlice} from "@reduxjs/toolkit";

const initialOrderHistoryState = {
  isLoading: false,
  notification: null,
  orders: {}
};

export default createSlice({
  name: "orderHistory",
  initialState: initialOrderHistoryState,
  reducers: {
    // OrderHistory Response Notification
    orderHistoryNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
    // OrderHistory Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },

    
    /*****
     *  OrderHistory Reducers
     */

    // fetchOrderHistory
    orderHistoryFetched: (state, action) => {
      state.isLoading = false;
      state.orders = {...state.orders, [action.payload.accountId]: action.payload.orders.ordersConditional};
    },

    // cancelOrder
    orderCancel: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
      // Update the cancel order in order history
      const order = action.payload.data;
      state.orders[order.accountId] = state.orders?.[order.accountId]?.map(item => item.id === order.id ? ({...order, ...item, status: order.status}) : item);
    },

    //
    orderHistoryAssetInfoFetched: (state, action) => {
      state.isLoading = false;
      const {accountId, ...assets} = action.payload;
      state.orders = {...state.orders, [accountId]: state.orders[accountId]?.map(item => ({...item, ...assets[`ass_${item.instrumentId?.replace(/-/g, "_")}`]?.asset, id: item.id}))};
    },

  }
});