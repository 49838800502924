import { put, takeLatest } from "@redux-saga/core/effects";
import { DEFAULT_WATCHLIST_DESCRIPTION } from "../../common/data/constants";
import { errorMessage } from "../../common/util/Helpers";
import { createWatchlistReq, fetchWatchlistsReq } from "./watchlistCrud";
import watchlistSlice from "./watchlistSlice";


/**
 * Saga Watchlist Middlewares (Side Effects)
 */

export default function* watchlistSaga() {
    // Watchlist Fetched Side Effect
    yield takeLatest("watchlist/watchlistFetched", function* watchlistFetchedSaga({payload: {instrument: {watchLists}}}) {
        try {
            // Create Favourites watchlist if not exist
            if (!watchLists.some(item => item.title.toUpperCase() === "FAVOURITES")) {
                const watchlist = {
                                        "title":"Favourites",
                                        "description": DEFAULT_WATCHLIST_DESCRIPTION,
                                        "assets": [],
                                        "meta": {
                                            "image": ""
                                        }
                                    }
                const {data: {data}} = yield createWatchlistReq(watchlist);
                yield put(watchlistSlice.actions.watchlistCreated({data, notification: null}));
            }
        }
        catch(err) {
            yield put(watchlistSlice.actions.watchlistNotification({ notification: {type: "error", message: errorMessage(err)}}));
        }
    });

    yield takeLatest(["watchlist/watchlistCreated", "watchlist/watchlistAssetsUpdated"], function* watchlistUpdateSaga({payload}){
        try {
            // Update Watchlist datas on new watch list created or Add/Remove asset from watchlist
            const {data: {data}} = yield fetchWatchlistsReq();
            yield put(watchlistSlice.actions.watchlistFetched(data));
        }
        catch(err) {
            yield put(watchlistSlice.actions.watchlistNotification({ notification: {type: "error", message: errorMessage(err)}}));
        }
    });


}
