import browserSignature from "browser-signature";
import { deviceDetect } from "react-device-detect";
import CryptoJS from 'crypto-js'


const deviceId = localStorage.getItem("device") ?? browserSignature();

// Add device ID to localstorage if not present
if (!localStorage.getItem("device")){
  localStorage.setItem("device", deviceId)
}

// Browser and OS Information
const deviveInfo = (function(){
                      const device = deviceDetect();
                      const {isBrowser, vendor, model, os, osName, osVersion, browserName, browserMajorVersion} = device;
                      return JSON.stringify({
                                              ...device, device: isBrowser ? `${osName} ${osVersion} ${browserName} ${browserMajorVersion}` : `${vendor} ${model} ${os} ${osVersion}`,
                                              manufacturer: isBrowser ? "Others" : vendor,
                                              "fingerprint": deviceId
                                            })
                    })();
/**
 * Intercept all axios request by injecting necessary headers
 * Intercept all axios response to check for graphql error and fail the request where applicable
 * @param {Redux} store redux store 
 * @param {Axios} axios axios instance
 */
const setupAxiosInterceptors = (store, axios) => {

    // Request Interceptor
    axios.interceptors.request.use(
      config => {
        const {auth: { token, appKey }} = store.getState();

        if (token) config.headers.Authorization = `Bearer ${token}`;
        if (appKey) config.headers["app-key"] = appKey;

        config.url = process.env.REACT_APP_API_HOST + config.url;
        config.headers["Content-Type"] = "application/json";
        config.headers["x-device-id"] = deviceId;
        config.headers["x-device-info"] = deviveInfo;
        config.headers["x-signature"] = CryptoJS.HmacSHA256(`${deviceId}${config.data ? JSON.stringify(config.data) : ""}`, process.env.REACT_APP_SECRET_KEY).toString();
        return config;
      },
      err => Promise.reject(err)
    );

    // Response Interceptor
    axios.interceptors.response.use(response => {
        if (response.config.url.substr(-8) === "/graphql" && response.data.errors) {
          // Return error for failed graphql request
          return Promise.reject(response);
        }
        return response;
      }, 
      err => Promise.reject(err)
    );
}

export default setupAxiosInterceptors;