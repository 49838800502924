import { put, takeLatest } from "@redux-saga/core/effects";
import { errorMessage } from "../../common/util/Helpers";
import { fetchAssetBySymbol } from "./assetsCrud";
import assetsSlice from "./assetsSlice";


/**
 * Saga Assets Middlewares (Side Effects)
 */

export default function* assetsSaga() {
    // Assets Details Fetched Side Effect
    yield takeLatest("assets/instrumentAssetDetailsFetched", function* assetsFetchedSaga({payload: {instrument}}) {
        try {
            // Fetch Similar Asset data
            const similar = instrument?.assets?.[0]?.similar?.map(item => item.replace(/\./g, ""));
            const country = instrument?.assets?.[0]?.country;

            if (country && similar?.length > 0){
                const {data: {data}} = yield fetchAssetBySymbol(country, similar);
                const similarAssets = [];
                for (const symbol of similar) {
                    data[symbol].assets?.[0] && similarAssets.push(data[symbol].assets?.[0]);
                }

                yield put(assetsSlice.actions.addAssetDetailsSimilarAssets(similarAssets));
            }
        }
        catch(err) {
            yield put(assetsSlice.actions.assetsNotification({ notification: {type: "error", message: errorMessage(err)}}));
        }
    });


}
