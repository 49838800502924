import { put, takeLatest } from "@redux-saga/core/effects";
import { errorMessage } from "../../common/util/Helpers";
import { fetchOrderHistoryAssetInfoReq } from "./orderHistoryCrud";
import orderHistorySlice from "./orderHistorySlice";


/**
 * Saga Order History Middlewares (Side Effects)
 */

export default function* orderHistorySaga() {
    // Order History Fetched Side Effect
    yield takeLatest("orderHistory/orderHistoryFetched", function* orderHistoryFetchedSaga({payload: {accountId, orders}}) {
        try {
            // Get Asset Image url and name
            const assetIds = [...new Set(orders?.ordersConditional?.map(item => item.instrumentId))];
            if (assetIds.length > 0){
                const {data: {data}} = yield fetchOrderHistoryAssetInfoReq(assetIds);
                yield put(orderHistorySlice.actions.orderHistoryAssetInfoFetched({...data, accountId}));
            }
        }
        catch(err) {
            yield put(orderHistorySlice.actions.orderHistoryNotification({ notification: {type: "error", message: errorMessage(err)}}));
        }
    });


}
