
/*****
 * 
 *  Order History GraphQl Query
 */


export const orderHistoryQuery = accountId => JSON.stringify({
                                                  query: `query($accountId: String!){
                                                    orders {
                                                      ordersConditional(order: { accountId: $accountId }, pagination: {limit: 10000}) {
                                                        created,
                                                        symbol,
                                                        side,
                                                        type,
                                                        currency,
                                                        country,
                                                        total,
                                                        price,
                                                        amount,
                                                        id,
                                                        instrumentId,
                                                        quantity,
                                                        status,
                                                        accountId,
                                                        meta,
                                                        fees {
                                                          flat,reason
                                                        }
                                                      }
                                                      }
                                                    }`,
                                                  variables: {
                                                    accountId
                                                  }
                                                });


export const orderHistoryAssetInfoQuery = assetIds => JSON.stringify({
                                                  query: `{
                                                      ${assetIds.reduce((acc, id) => acc + `ass_${id.replace(/-/g, "_")}: instrument{ asset(assetId: "${id}"){imageUrl, name, symbol, id}} ` , "")} 
                                                }`,
                                                  variables: {
                                                  }
                                              });