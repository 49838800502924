import React from 'react';
import ReactDOM from 'react-dom';
import './scss/index.scss';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { SplashScreenProvider } from './app/common/layout/layout-components/LayoutSplashScreen';
import store from './app/redux/store';
import axios from "axios";
import setupAxiosInterceptors from './app/redux/setupAxiosInterceptors';


const { PUBLIC_URL } = process.env;

/**
 * Inject interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxiosInterceptors(store, axios)

ReactDOM.render(
  <React.StrictMode>
    <SplashScreenProvider>
      <App store={store} basename={PUBLIC_URL} />
    </SplashScreenProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
