import cogoToast from "cogo-toast";
import { isMobile } from "react-device-detect";
import SVG from "react-inlinesvg/esm";

/**
 * Custom Toast Message built on cogoToast (https://github.com/Cogoport/cogo-toast)
 * 
 * @param {string} type (error, success, warn, info) 
 * @param {string} message to display
 */
const toastMessage = (type, message) => {

    let toast;

    const options = {
        position: isMobile ? "top-center" : "bottom-center",
        bar: { size: '0px' },
        hideAfter: 5,
        renderIcon: _ => <SVG
                            src={`/assets/media/svg/toast/${type}.svg`}
                            className="stroke-current inline-block"
                        />
    }

    toast = cogoToast[type]?.(<div className="cont">
                                    <div>{message} </div>
                                    <SVG
                                        src="/assets/media/svg/close.svg"
                                        onClick={_ => toast?.hide()}
                                    />
                            </div>, options);
}

export default toastMessage;