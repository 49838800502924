import { createSlice } from "@reduxjs/toolkit";
import toastMessage from "../../common/util/toastMessage";


const initialAssetsState = {
  isLoading: false,
  notification: null,
  activeAsset: null,
  assets: null,
  assetListEndCheck: { ngAsset: false, foreignAsset: false, etfAsset: false, cryptoAsset: false, frAsset:false },
  assetDetails: {},
  // frAssetDetails: {},
  recentAssets: null,
  trendingAssets: null,
  searchAssets: [],
  wsConnected: null,
  quotes: {},
  currentOrder: null,
  orderReview: null
};

export default createSlice({
  name: "assets",
  initialState: initialAssetsState,
  reducers: {
    // Assets Response Notification
    assetsNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
    // Assets Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },


    /*****
     *  Assets Reducers
     */

    // setWebSocket
    webSocketConnected: (state, _) => {
      state.wsConnected = new Date().toLocaleString();
    },

    // addLiveQuoteAsset
    addToQuoteAssetList: (state, action) => {
      const { symbol, exchange } = action.payload;
      const key = `${symbol}:${exchange}`;
      // Add to list or increment count if already exist
      let asset = state.quotes[key];
      if (asset) {
        asset.count++;
      }
      else {
        asset = { count: 1, quote: null };
      }

      state.quotes[key] = asset;
    },

    // removeLiveQuoteAsset
    removeFromQuoteAssetList: (state, action) => {
      const { symbol, exchange } = action.payload;
      const key = `${symbol}:${exchange}`;
      // Remove from list or decrease count if already exist
      let asset = state.quotes[key];
      if (asset) {
        if (asset.count > 1) {
          asset.count--;
          state.quotes[key] = asset;
        }
        else {
          delete state.quotes[key];
        }
      }

    },

    // newLiveQuote
    updateQuote: (state, action) => {
      if (!action.payload) return state;
      const { symbol, exchange } = action.payload;
      let key = `${symbol}:${exchange}`;
      if (state.quotes[key]) {
        state.quotes[key].quote = action.payload;
      }
      else {
        key = Object.keys(state.quotes ?? {})?.find(item => item.startsWith(`${symbol}:`));
        if (key) {
          state.quotes[key].quote = action.payload;
        }
      }

      // Append Quote to today historical data
      if (state.assetDetails?.symbol === symbol && (state.assetDetails?.quote?.exchange ?? state.assetDetails?.exchange) === exchange) {
        const lastQuote = state.assetDetails?.historicalData?.today?.slice(-1)?.[0] ?? {};
        const newQuote = action.payload;
        // Only append when there is changes in either open, close, high, or low value
        if (state.assetDetails?.historicalData?.today && (lastQuote.open !== newQuote.open || lastQuote.close !== newQuote.close || lastQuote.high !== newQuote.high || lastQuote.low !== newQuote.low)) {
          state.assetDetails.historicalData.today = [...(state.assetDetails?.historicalData?.today?.slice(1) ?? []), action.payload];
        }
      }
    },

    // fetchAllAssets
    allAssetsFetched: (state, action) => {
      state.isLoading = false;
      const { ngAsset, foreignAsset, etfAsset, cryptoAsset, frAsset } = action.payload;
      state.assets = { ngAsset: ngAsset.assets, foreignAsset: foreignAsset.assets, etfAsset: etfAsset.assets, cryptoAsset: cryptoAsset.assets, frAsset:frAsset.assets };
    },

    // loadMoreAssets
    moreAssetsLoaded: (state, action) => {
      state.isLoading = false;
      const { group, ...assets } = action.payload;
      state.assetListEndCheck[group] = (assets?.[group]?.assets ?? []).length < 20; // mark as list end if return asset is less than limit
      state.assets = { ...state.assets, [group]: [...state.assets?.[group], ...(assets?.[group]?.assets ?? [])] };
    },

    // fetchAssetGroupHistoricalData
    assetGroupHistoricalDataFetched: (state, action) => {
      state.isLoading = false;

      const { offset, group, ...history } = action.payload;
      const assets = state.assets?.[group];
      const end = offset + 20;

      for (let i = offset; i < end; i++) {
        if (assets[i]) {
          assets[i].historicalData = history[`ass_${assets[i].id?.replace(/-/g, "_")}`]?.historicalData ?? [];
        }
      }
    },

    // fetchInstrumentAssetDetails 
    loadingAssetDetails: (state, action) => {
      state.isLoading = true;
      if (action.payload.country?.toUpperCase() !== state.assetDetails?.country || action.payload.symbol?.toUpperCase() !== state.assetDetails?.symbol) {
        // Clear previous asset details
        state.assetDetails = {}
      }
    },

    // fetchInstrumentAssetDetails
    instrumentAssetDetailsFetched: (state, action) => {
      state.isLoading = false;
      if (!action.payload.instrument?.assets?.[0]) {
        return toastMessage("error", "Unable to load Asset Details");
      }
      state.assetDetails = { ...state.assetDetails, ...action.payload.instrument?.assets?.[0], quote: action.payload.instrument?.fundamentals, assetDetail: action.payload.instrument?.asset.assetDetail };
    },
    // 
    // fetchMarketInsightAssetDetails
    marketInsightAssetDetailsFetched: (state, action) => {
      state.isLoading = false;
      state.assetDetails = { ...state.assetDetails, ...action.payload };
    },

    // fetchPortfolioPerformanceAssetDetails
    portfolioPerformanceAssetDetailsFetched: (state, action) => {
      state.isLoading = false;
      state.assetDetails = { ...state.assetDetails, ...action.payload, historicalData: { otherDays: action.payload.otherDays.historicalData ?? [], today: action.payload.today.historicalData ?? [] }, today: undefined, otherDays: undefined };
    },

    // fetchOrderAssetDetails
    ordersAssetDetailsFetched: (state, action) => {
      state.isLoading = false;
      state.assetDetails = { ...state.assetDetails, ...action.payload };
    },

    // fetchSingleAssetHistoricalData
    singleAssetHistoricalDataFetched: (state, action) => {
      state.isLoading = false;
      if (state.assetDetails.id === action.payload.id) {
        state.assetDetails.historicalData[action.payload.range] = action.payload.instrument.historicalData;
      }
    },

    // fetchFrAssetDetails
    // frAssetDetailsFetched:(state, action)=>{
    //   state.isLoading = false;
    //   if (state.assetDetails.id === action.payload.id) {
    //     state.frAssetDetails = action.payload.instrument.assetDetail;
    //   }
    // },

    // assetsFetchedSaga
    addAssetDetailsSimilarAssets: (state, action) => {
      state.isLoading = false;
      state.assetDetails.similarAssets = action.payload;
    },

    // fetchTrendingRecentAssets
    trendingRecentAssetsFetched: (state, action) => {
      state.isLoading = false;
      const { marketInsight, ...recentsObj } = action.payload;
      const recents = [];
      for (const key in recentsObj) {
        recents.push(recentsObj[key].asset)
      }
      state.recentAssets = recents;
      state.trendingAssets = marketInsight.mostActive;
    },

    // clearRecentAssets
    recentAssetsCleared: (state) => {
      state.recentAssets = [];
    },

    // fetchSearchAsset
    searchAssetFetched: (state, action) => {
      state.isLoading = false;
      state.searchAssets = action.payload.instrument.search;
    },

    assetOrderCreationReview: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
      state.orderReview = action.payload.data;
    },

    assetOrderCreated: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
      state.currentOrder = action.payload.data;
    }
  }
});