import {createSlice} from "@reduxjs/toolkit";

const initialCalendarState = {
  isLoading: false,
  notification: null,
  earnings: null,
  dividend: null,
  ipo: null,
  upgrades: null,
  downgrades: null
};

export default createSlice({
  name: "calendar",
  initialState: initialCalendarState,
  reducers: {
    // Calendar Response Notification
    calendarNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
    // Calendar Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },

    
    /*****
     *  Calendar Reducers
     */

    // fetchEarnings
    earningsFetched: (state, action) => {
      state.isLoading = false;
      state.earnings = action.payload.marketInsight.upcomingEarningsReport;
    },

    // fetchDividend
    dividendFetched: (state, action) => {
      state.isLoading = false;
      state.dividend = action.payload.marketInsight.upcomingDividends;
    },

    // fetchIPO
    IPOFetched: (state, action) => {
      state.isLoading = false;
      state.ipo = action.payload.marketInsight.upcomingIPOs;
    },

  }
});