import { put, takeLeading, takeLatest, delay } from "@redux-saga/core/effects";
import { errorMessage, objToArray } from "../../common/util/Helpers";
import { loginReq, migrationStatusReq, refreshTokenReq, sendDeviceOTPReq } from "./authCrud";
import authSlice from "./authSlice";
import { REHYDRATE} from 'redux-persist';


/**
 * Saga Auth Middlewares (Side Effects)
 */

export default function* authSaga() {

    /* Authenticated Side Effect */

    // Token Refresh Recursion
    yield takeLatest(["auth/authenticated", "auth/loggedOut", REHYDRATE], function* refreshTokenSaga(action) {
        // Terminate Tooken Refresh on logout
        if (action?.type === "auth/loggedOut") return;
        try {
            // Persistor call on Authenticated page refresh
            if (action?.type === REHYDRATE && action?.payload?.auth?.token && action?.payload?.auth?.refreshToken) {
                const authExpire = Number(localStorage.getItem("auth-expire") ?? Date.now());
                const refreshDelay = authExpire - Date.now();
                // Refresh token
                yield delay(refreshDelay); 
                const {data: {data}} = yield refreshTokenReq(action?.payload?.auth?.refreshToken);
                yield put(authSlice.actions.tokenRefreshed(data));
                yield refreshTokenSaga({type: "auth/authenticated", payload: data});
            }
            else if (action?.type === "auth/authenticated") {
                // Regenerate token every 5 hours
                const nextReAuth = 5 * 60 * 60 * 1000;
                localStorage.setItem("auth-expire", Date.now() + nextReAuth)
                yield delay(nextReAuth);    // 5 Hours delay
                const {data: {data}} = yield refreshTokenReq(action?.payload?.refreshToken);
                yield put(authSlice.actions.tokenRefreshed(data));
                yield refreshTokenSaga({type: "auth/authenticated", payload: data});
            }
        }
        catch(err) {
            // Error Notification
            yield put(authSlice.actions.authNotification({ notification: {type: "error", message: errorMessage(err)}}));
        }
    });

    yield takeLeading("auth/authenticated", function* authenticatedSaga({payload: {statusCode, history, email, ...authResp}}) {
        try {
            if (statusCode === 201) {
                // Login OTP Effect
                yield sendDeviceOTPReq();
                history.push("/2fa-otp");
            }
            else if (statusCode === 405){
                // Login Migration Effect
               yield history.push("/migration");
            }
            else if (!statusCode) {
                // New registration Effect
                const {headers, data: {data}} = yield loginReq(email, authResp.password);
                yield put(authSlice.actions.authenticated({...data, headers, statusCode: 200, authenticated: false}));
                yield sendDeviceOTPReq();
            }
        }
        catch(err) {
            yield put(authSlice.actions.authNotification({ notification: {type: "error", message: errorMessage(err)}}));
        }
    });

    // Migration Status Check
    yield takeLatest("auth/authenticated", function* migrationStatusSaga(action) {
        try {
            if (action.payload?.statusCode === 405){
                const {data: {data}} = yield migrationStatusReq(action.payload?.email);
                yield put(authSlice.actions.updateMigrationStatus(data));
                const  dataArr = objToArray(data);
                if (dataArr.some(item => item[1] === 0)){
                    yield delay(2 * 1000);    // 2 seconds delay
                    yield migrationStatusSaga(action);
                }
                else if (dataArr.every(item => (item[1] !== -1 && item[1] !== 0))){
                    // All Done, log user In
                    const {status, data: {data}} = yield loginReq(action.payload.email, action.payload.password);
                    yield put(authSlice.actions.authenticated({...action.payload, ...data, statusCode: status}));
                }
            }
        }
        catch(err) {
            yield put(authSlice.actions.authNotification({ notification: {type: "error", message: errorMessage(err)}}));
        }
    });

}
