import axios from "axios";
import { assetsHistoricalDataQuery, catAssetsQuery, dashboardDataQuery, loadMoreAssetsInCategoryQuery } from "./dashboardQuery";

/*****
 * 
 *  Dashboard Request
 */

export const fetchDashboardDataReq = _ => {
  return axios.post("/graphql", dashboardDataQuery());
}

export const fetchCatAssetsReq = (provider, catIds) => {
  return axios.post("/graphql", catAssetsQuery(provider, catIds));
}

export const loadMoreAssetsInCategoryReq = (provider, catId, offset) => {
  return axios.post("/graphql", loadMoreAssetsInCategoryQuery(provider, catId, offset));
}

export const fetchAssetsHistoricalDataReq = (assetIds, start, end) => {
  return axios.post("/graphql", assetsHistoricalDataQuery(assetIds, start, end));
}