
/*****
 * 
 *  Watchlist GraphQl Query
 */

import { ASSET_QUERY_FIELDS } from "../../common/data/constants";


export const watchlistQuery = _ => JSON.stringify({
                                                  query: `query{
                                                      instrument {
                                                        watchLists(
                                                            watchList:{}, pagination: {limit: 2000}){
                                                          assets {
                                                            ${ASSET_QUERY_FIELDS}
                                                          },
                                                          accountId,
                                                          created,
                                                          description,
                                                          id,
                                                          title,
                                                          meta
                                                        }
                                                      }
                                                    }`,
                                                  variables: {
                                                    
                                                  }
                                                });



export const watchlistAssetHistoricalDataQuery = (assetIds, start, end) => JSON.stringify({
                                                query: `{
                                                    
                                                    ${assetIds.reduce((acc, id) => acc + ` ass_${id.replace(/-/g, "_")}: instrument { historicalData(asset: {id: "${id}"}, startDate: ${start}, endDate: ${end}, period: "1D"){ close, date }} ` , "")} 
                                                  
                                              }`,
                                                variables: {
                                                }
                                              })
