import {combineReducers} from "redux";
import {all} from "redux-saga/effects";
import assetsSaga from "./assets/assetsSaga";
import assetsSlice from "./assets/assetsSlice";
import authSaga from "./auth/authSaga";
import authSlice from "./auth/authSlice";
import calendarSlice from "./calendar/calendarSlice";
import cardSlice from "./card/cardSlice";
import dashboardSaga from "./dashboard/dashboardSaga";
import dashboardSlice from "./dashboard/dashboardSlice";
import freeSharesSlice from "./free-shares/freeSharesSlice";
import giftStocksSlice from "./gift-stocks/giftStocksSlice";
import giftCardSlice from "./gift-card/giftCardSlice";
import marketInsightSlice from "./market-insight/marketInsightSlice";
import orderHistorySaga from "./order-history/orderHistorySaga";
import orderHistorySlice from "./order-history/orderHistorySlice";
import portfolioSaga from "./portfolio/portfolioSaga";
import portfolioSlice from "./portfolio/portfolioSlice";
import profileSlice from "./profile/profileSlice";
import settingsSlice from "./settings/settingsSlice";
import vaultSlice from "./vault/vaultSlice";
import watchlistSaga from "./watchlist/watchlistSaga";
import watchlistSlice from "./watchlist/watchlistSlice";


const appReducer = combineReducers({
  auth: authSlice.reducer,
  profile: profileSlice.reducer,
  calendar: calendarSlice.reducer,
  portfolio: portfolioSlice.reducer,
  orderHistory: orderHistorySlice.reducer,
  vault: vaultSlice.reducer,
  card: cardSlice.reducer,
  watchlist: watchlistSlice.reducer,
  assets: assetsSlice.reducer,
  dashboard: dashboardSlice.reducer,
  giftStocks: giftStocksSlice.reducer,
  giftCard: giftCardSlice.reducer,
  marketInsight: marketInsightSlice.reducer,
  freeShares: freeSharesSlice.reducer,
  settings: settingsSlice.reducer,
});

export const rootReducer = (state, action) => {
  if (action.type === 'auth/loggedOut') {
    /* Clear All Data on logout */
    state = undefined;
    sessionStorage.setItem("logout", "xxxx"); // To use on re-login with current browser session
    // Delete Active AccountId and Mode on logout
    sessionStorage.removeItem("activeAccountId");
    sessionStorage.removeItem("accountMode");
    localStorage.removeItem("openInApp");
  }
  
  return appReducer(state, action)
}


export function* rootSaga() {
  yield all([authSaga(), portfolioSaga(), assetsSaga(), watchlistSaga(), orderHistorySaga(), dashboardSaga()]);
}

