import { createSlice } from "@reduxjs/toolkit";
import { compareDates } from "../../common/util/Helpers";

const initialVaultState = {
  isLoading: false,
  notification: null,
  transactionHistory: null,
  depositTransactionDetails: null,
  initDepositTransactionDetails: null,
  transferReceiver: null,
  paymentReview: null,
  showCreateVaultModal: false,
  instantDepositStatus: false
};

export default createSlice({
  name: "vault",
  initialState: initialVaultState,
  reducers: {
    // Vault Response Notification
    vaultNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
    // Vault Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },

    // toggleCreateVaultModal
    toggleModal: (state) => {
      state.showCreateVaultModal = !state.showCreateVaultModal;
    },

    /*****
     *  Vault Reducers
     */

    // FetchVaultDetails
    vaultDetailsFetched: (state, action) => {
      state.isLoading = false;
      const paymentHistory = (action.payload.payment.accountPaymentHistory ?? []).filter(item => item.status?.toLowerCase() !== "completed");
      const accountHistory = (action.payload.account.accountHistory ?? []).filter(item => (item.tranStatus?.toLowerCase() !== "pending" && item.tranStatus?.toLowerCase() !== "processing"));
      state.transactionHistory = [...paymentHistory, ...accountHistory];
      state.transactionHistory.sort((a, b) => compareDates((a.createdAt ?? a.created), (b.createdAt ?? b.created), false));
    },

    depositTransactionDetailsChecked: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
      state.depositTransactionDetails = action.payload.payment ? action.payload.payment.transactionDetails : action.payload;
    },

    depositTransactionDetailsReset: (state) => {
      state.isLoading = false;
      state.paymentReview = null;
      state.initDepositTransactionDetails = null;
      state.depositTransactionDetails = null;
    },

    initDepositTransactionDetailsReset: (state) => {
      state.isLoading = false;
      state.initDepositTransactionDetails = null;
    },

    instantDepositStatusFetched: (state, action) => {
      state.isLoading = false;
      state.instantDepositStatus = action.payload.status;
    },

    paymentInitiated: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
      state.initDepositTransactionDetails = action.payload.data;
    },

    transferReceiverFetched: (state, action) => {
      state.isLoading = false;
      state.transferReceiver = action.payload.data;
      state.notification = action.payload.notification;
    },

    transferred: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },

    paymentReviewFetched: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
      state.paymentReview = action.payload.data;
    },

  }
});