import axios from "axios";
import { portfolioQuery, portfolioDetailsQuery, portfolioAssetHistoricalDataQuery, marketStatusQuery, portfolioTransactionsQuery, wireDepositAccountQuery, portfolioAssetRecommendationQuery } from "./portfolioQuery";


/*****
 * 
 *  Portfolio Request
 */

 export const fetchPortfoliosReq = _ => {
  return axios.post("/graphql", portfolioQuery() );
}

export const fetchPortfolioDetailsReq = (accountIds, riskScore) => {
  return axios.post("/graphql", portfolioDetailsQuery(accountIds, riskScore) );
}

export const createPortfolioReq = partner => {
  return axios.post("/accounts", {partner} );
}

export const fetchPortfolioAssetHistoricalDataReq = (assetIds, start, end) => {
  return axios.post("/graphql", portfolioAssetHistoricalDataQuery(assetIds, start, end) );
}

export function downloadStatementReq(accountId, key){
  return axios.get(`/accounts/${accountId}/statements/${key}/download`, {responseType: 'blob'});
}

export function fetchMarketStatusReq(){
  return axios.post("/graphql", marketStatusQuery() );
}

export function fetchPortfolioTransactionsReq(accountId){
  return axios.post("/graphql", portfolioTransactionsQuery(accountId) );
}

export function fetchPortfolioAssetRecommendationReq(accountId, riskScore){
  return axios.post("/graphql", portfolioAssetRecommendationQuery(accountId, riskScore) );
}

export function fetchWireDepositAccountReq(partner, accountId){
  return axios.post("/graphql", wireDepositAccountQuery(partner, accountId) );
}

export const checkInstantDepositStatusReq = data => {
  return axios.post("/payment/deposit/instant-payment", { ...data } );
}

export const toggleExtendedeHoursReq = (accountId, extendedHoursEnabled) => {
  return axios.post(`/accounts/update-extended-hours-status/${accountId}/${extendedHoursEnabled}`, {} );
}
