import { createSlice } from "@reduxjs/toolkit";


const initialDashboardState = {
  isLoading: false,
  notification: null,
  categories: null,
  news: null,
  topGainers: null,
  topLosers: null,
  assetListEndCheck: { },
};

export default createSlice({
  name: "dashboard",
  initialState: initialDashboardState,
  reducers: {
    // Dashboard Response Notification
    dashboardNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
    // Dashboard Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },

    /*****
     *  Dashboard Reducers
     */


    // fetchDashboardData
    dashboardDataFetched: (state, action) => {
      state.isLoading = false;
      state.news = action.payload.marketInsight.news ?? [];
      state.topGainers = action.payload.marketInsight.topGainers?.sort((a,b) => (b.quote?.priceChangePercent ?? 0) - (a.quote?.priceChangePercent ?? 0) ) ?? [];
      state.topLosers = action.payload.marketInsight.topLosers?.sort((a,b) => (a.quote?.priceChangePercent ?? 0) - (b.quote?.priceChangePercent ?? 0) ) ?? [];
      state.categories = action.payload.instrument?.themes?.sort((a, b) => (b?.meta?.priority ?? 0) - (a?.meta?.priority ?? 0)) ?? [];
    },

    // dashboardDataFetchedSaga
    catAssetsFetched: (state, action) => {
      state.isLoading = false;
      state.categories = state.categories?.map(item => ({...item, assets: action.payload[`ass_${item.id.replace(/[-.]/g, "_")}`]?.theme?.assets ?? []}));
    },

    // loadMoreAssetsInCategory
    moreAssetsInCategoryLoaded: (state, action) => {
      state.isLoading = false;
      const { catId, instrument } = action.payload;
      state.assetListEndCheck[catId] = (instrument.theme?.assets ?? []).length < 20; // mark as list end if return asset is less than limit
      state.categories = state.categories?.map(item => item.id === catId ? ({...item, assets: [...item.assets, ...(instrument.theme?.assets ?? [])]}) : item);
    },

    // fetchAssetsHistoricalData
    assetsHistoricalDataFetched: (state, action) => {
      state.isLoading = false;
      const { offset, catId, ...history } = action.payload;
      const index = state.categories?.findIndex(item => item.id === catId);
      const assets = state.categories?.[index]?.assets;
      const end = offset + 20;

      for (let i = offset; i < end; i++) {
        if (assets?.[i]) {
          assets[i].historicalData = history[`ass_${assets[i].id?.replace(/-/g, "_")}`]?.historicalData ?? [];
        }
      }
    }
  }
});