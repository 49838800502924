import {createSlice} from "@reduxjs/toolkit";

const initialGiftCardState = {
  isLoading: false,
  notification: null,
  giftCardHistory: []
};

export default createSlice({
  name: "giftCard",
  initialState: initialGiftCardState,
  reducers: {
    // GiftCard Response Notification
    // giftCardNotification: (state, action) => {
    //   state.isLoading = false;
    //   state.notification = action.payload.notification;
    // },
    // GiftCard Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },

    
    /*****
     *  Gift Stock Reducers
     */

    // fetchGiftStocks
    giftCardHistoryFetched: (state, action) => {
      state.isLoading = false;
      state.giftCardHistory=action.payload.promotion.giftCards;
    },
    

    // fetchGiftReceiver
    // giftReceiverFetched: (state, action) => {
    //   state.isLoading = false;
    //   state.giftReceiver = action.payload;
    // },

    // giftStock
    // stockGifted: (state, action) => {
    //   state.isLoading = false;
    //   state.notification = action.payload.notification;
    //   state.sentGiftedStocks = [action.payload.data, ...state.sentGiftedStocks];
    // },

    // redeemGift
    // giftRedeemed: (state, action) => {
    //   state.isLoading = false;
    //   state.notification = action.payload.notification;
    //   const gift = action.payload.data;
    //   state.receivedGiftedStocks = state.receivedGiftedStocks?.map(item => item.id === gift.id ? ({...item, ...gift, asset: item.asset}) : item);
    // },

    // // cancelGift
    // giftCancelled: (state, action) => {
    //   state.isLoading = false;
    //   state.notification = action.payload.notification;
    //   const gift = action.payload.data;
    //   state.sentGiftedStocks = state.sentGiftedStocks?.map(item => item.id === gift.id ? ({...item, ...gift, asset: item.asset}) : item);
    // },

    // // updateNotifications
    // notificationsUpdated: (state, action) => {
    //   state.notificationOptions = action.payload.allowedEvents;
    // },
  }
  
});