import {createSlice} from "@reduxjs/toolkit";
import { FAVOURITES_WATCHLIST_IMAGE_URL } from "../../common/data/constants";

const initialWatchlistState = {
  isLoading: false,
  notification: null,
  watchlists: null
};

export default createSlice({
  name: "watchlist",
  initialState: initialWatchlistState,
  reducers: {
    // Watchlist Response Notification
    watchlistNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
    // Watchlist Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },

    
    /*****
     *  Watchlist Reducers
     */

    // fetchWatchlist
    watchlistFetched: (state, action) => {
      state.isLoading = false;
      const watchlists = action.payload.instrument.watchLists;
      // Rearrange to Ensure Favourites is the first in the list and the image is override with favourites image
      state.watchlists = !watchlists ? [] : [...watchlists.filter(item => item.title?.toUpperCase() === "FAVOURITES").map(item => ({...item, meta: {image : FAVOURITES_WATCHLIST_IMAGE_URL}})), ...watchlists.filter(item => item.title?.toUpperCase() !== "FAVOURITES") ];
    },

    // createWatchlist
    watchlistCreated: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
      // Add new asset and overide asset list as empty because the asset list only contain asset id without details
      state.watchlists = [...state.watchlists, {...action.payload.data, assets: []}] ;  
    },

    // updateWatchlistDetails
    watchlistDetailsUpdated: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
      // Update Watchlist details excluding the asset field
      state.watchlists = state.watchlists.map(item => item.id === action.payload.data.id ? ({...item, ...action.payload.data, assets: item.assets}) : item);  
    },

    // updateWatchlistAssets
    watchlistAssetsUpdated: (state, action) => {
      // Change isLoading Status and notification only and allow  watchlistUpdateSaga to refresh watchlist
      state.isLoading = false; 
      state.notification = action.payload.notification ?? null;
    },

    // removeWatchlistAsset
    watchlistAssetRemoved: (state, action) => {
      state.isLoading = false;
      // Remove deleted Asset from the specificied watchlist assets
      const {id, removedAssetId} = action.payload;
      state.watchlists = state.watchlists.map(item => item.id === id ? ({...item, assets: item.assets.filter(ass => ass.id !== removedAssetId) }) : item) ;  
    },

    // fetchWatchlistAssetHistoricalData
    watchlistAssetHistoricalDataFetched: (state, action) => {
      state.isLoading = false;
      // Update asset historical data in the active watchlist
      state.watchlists = state.watchlists?.map(item => item.id === action.payload.id ? ({...item, assets: item.assets.map(asset => ({...asset, historicalData: action.payload[`ass_${asset.id?.replace(/-/g, "_")}`]?.historicalData ?? []}) )}) : item)
    },

  }
});