import { createSlice } from "@reduxjs/toolkit";
import { getCategoryDetailsProviders } from "../../common/util/Helpers";

const initialProfileState = {
  isLoading: false,
  notification: null,
  kyc: {},
  document: [],
  riskProfile: null,
  nairaBankAccount: null,
  dollarBankAccount: null,
  banks: null,
  bankAccountName: null,
  savedCards: null,
  pin: null,
  enablePin: false,
  momoAvailable: null,
  provider: { demo: "zanibal,alpaca", live: null }
};

export default createSlice({
  name: "profile",
  initialState: initialProfileState,
  reducers: {
    // Profile Response Notification
    profileNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
    // Profile Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },

    /*****
     *  Profile Reducers
     */

    // getProfile
    profileFetched: (state, action) => {
      const bankAccounts = action.payload.payment.bankAccounts;
      const savedCards = action.payload.payment.saveCards ?? [];
      const momoAvailable = action.payload.payment.supportedMOMOOption;
      const user = action.payload.user;
      const nationality = action.payload.user.userKyc.nationality;
      const portfolios = action.payload.account.completeUserAccounts;
      return {
        ...state, ...user.userDetails, isLoading: false, kyc: user?.userKyc ?? {}, document: user.userDocs?.reverse(),
        nairaBankAccount: bankAccounts?.find(item => item.currency?.search("NGN") >= 0),
        dollarBankAccount: bankAccounts?.find(item => item.currency?.search("USD") >= 0),
        savedCards: savedCards,
        momoAvailable: momoAvailable,
        provider: { ...state.provider, live: getCategoryDetailsProviders(nationality,portfolios) }
      };
    },

    // FetchBanks
    banksFetched: (state, action) => {
      state.isLoading = false;
      state.banks = action.payload.payment.banks;
    },

    // FetchBankAccountDetails
    bankAccountNameFetched: (state, action) => {
      state.isLoading = false;
      state.bankAccountName = action.payload.data.payment.bankAccountName;
      state.notification = action.payload.notification;
    },

    // BankAccountDetailsCancelled
    bankAccountDetailsReset: (state) => {
      state.isLoading = false;
      state.bankAccountName = null;
    },

    // addNairaBankAccount
    nairaBankAccountAdded: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
      state.nairaBankAccount = action.payload;
    },

    // addDollarBankAccount
    dollarBankAccountAdded: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
      state.dollarBankAccount = action.payload;
    },

    // updateUserPicture
    userPictureUpdated: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
      state.document = [...state.document, action.payload];
    },

    // updateKYC
    kycUpdated: (state, action) => {
      state.isLoading = false;
      state.kyc = { ...state.kyc, ...action.payload };

    },

    // uploadDocument
    documentUploaded: (state, action) => {
      state.isLoading = false;
      state.document = [...state.document, action.payload];
    },

    // addRiskProfile
    riskProfileAdded: (state, action) => {
      state.isLoading = false;
      state.riskProfile = action.payload;
    },

    // addFirstLastName
    firstLastNameAdded: (state, action) => {
      return { ...state, ...action.payload, isLoading: false };
    },

    // setPin
    pinSet: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
      state.pin = action.payload.data;
    },

    pinStateChanged: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
      state.enablePin = action.payload.data;
    }
  }
});