import publicIp from "public-ip";
import { supportedCountries } from "../data/countries";
import { FEES } from "../data/constants";
import toastMessage from "./toastMessage";

export const convertUTCToLocalDate = datetime => {
    if (datetime?.toLocaleString().trim().length !== 10) {
        if (datetime?.toLocaleString().trim().length >= 22) {
            return new Date(datetime);
        }
        const curDate = new Date();
        datetime = (new Date(datetime)).getTime() - (curDate.getTimezoneOffset() * 60000);
    }
    return datetime ? new Date(datetime) : null
}

/**
 * 18 plus check
 * @param {*} _ 
 * @returns 
 */
export const getAllowedMaxDate = _ => {
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 18);
    return maxDate.toISOString().split('T')[0];
}

export const getTheme = _ => {
    if (localStorage.getItem("marketStatusTheme")) {
        return localStorage.getItem("marketStatusTheme");
    }
    if (localStorage.getItem("theme") === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        return "dark"
    }

    return "light";
}

export const formatDay = (datetime, withToAndYestreday = false) => {
    const event = new Date(convertUTCToLocalDate(datetime));

    if (withToAndYestreday && event.toDateString() === new Date().toDateString()) {
        return "Today"
    }

    if (withToAndYestreday && event.toDateString() === new Date((new Date()).valueOf() - 1000 * 60 * 60 * 24).toDateString()) {
        return "Yesterday"
    }

    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return datetime ? event.toLocaleDateString('en-US', options) : "None";
}

export const formatDate = datetime => {
    const event = new Date(convertUTCToLocalDate(datetime));
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
    return datetime ? event.toLocaleDateString('en-US', options) : "None";
}
export const formatOnlyDate = datetime => {
    const event = new Date(convertUTCToLocalDate(datetime));
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour12: true };
    return datetime ? event.toLocaleDateString('en-US', options) : "None";
}

export const formatTime = datetime => {
    const event = new Date(convertUTCToLocalDate(datetime));
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return datetime ? event.toLocaleTimeString('en-US', options) : "None";
}

export const formatLongDate = datetime => {
    const event = new Date(convertUTCToLocalDate(datetime));
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return datetime ? event.toLocaleDateString('en-US', options) : "None";
}

export const formatMonthYear = datetime => {
    const event = new Date(convertUTCToLocalDate(datetime));
    const options = { month: 'short', year: 'numeric' };
    return datetime ? event.toLocaleDateString('en-US', options)?.replace(' ', ', ') : "None";
}

export const formatDateWithOrdinal = datetime => {
    const dd = new Date(convertUTCToLocalDate(datetime));
    const date = dd.getDate();
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][dd.getMonth()];
    return `${date}${nth(date)} ${month}, ${dd.getFullYear()}`;
}

export const formatDayNth = datetime => {
    const dd = new Date(convertUTCToLocalDate(datetime));
    const date = dd.getDate();
    const day = ["Sun", "Mon", "Tues", "wed", "Thur", "Fri", "Sat"][dd.getDay()];
    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][dd.getMonth()];
    return `${day}, ${date}${nth(date)} ${month}, ${dd.getFullYear()}`;
}

/**
 * Nth affix to number
 * @param {number} d 
 * @returns 
 */
export const nth = d => {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
    }

}

export const formatAdminType = adminLevel => {
    return ["", "Admin", "Super Admin", "Admin"][adminLevel];
}

export const statusColourName = status => {
    const color = { pending_confirmation: "info", pending: "info", processing: "warning", success: "success-1", active: "success-1", completed: "success-1", disabled: "danger-1", verified: "success-1", approved: "success-1", deleted: "danger-1", cancelled: "danger-1", cancelling: "danger-1", failed: "danger-1", rejected: "danger-1" };
    return color[status?.toLowerCase()] ?? "gray-1";
}
export const formatStatus = status => {
    const color = { pending_confirmation: "info", pending: "info", processing: "warning", success: "primary", active: "primary", completed: "primary", enabled: "primary", disabled: "danger", verified: "primary", approved: "primary", deleted: "danger", cancelled: "danger", failed: "danger", rejected: "danger" };
    return status ? (<span className={`label label-lg label-light-${color[status?.toLowerCase()]} label-inline`}>
        {status?.replace(/_/g, " ")}
    </span>) : null;
}

/**
 * Format Quantity as appropriate
 * @param {number} quantity 
 * @returns 
 */
export const formatQuantity = quantity => {
    return `${Math.abs(quantity ?? 0).toLocaleString(undefined, { maximumFractionDigits: 8 })}`
}

/**
 * Format Amount as appropriate
 * @param {string} currency 
 * @param {number} amount 
 * @param {boolean} hide to return asterisks instead of number
 * @param {string} positiveSign 
 * @returns 
 */
export const formatAmount = (currency, amount, hide = false, positiveSign = "", isCrypto = false) => {
    if (hide) {
        return <strong>{getCurrencySign(currency)} **,***</strong>;
    }
    const amt = convertToNumber(amount) ?? 0;
    const maximumFractionDigits = Math.abs(amt) < 0.01 ? 8 : 2;
    return `${amt < 0 ? "-" : positiveSign}${getCurrencySign(currency) ?? currency ?? ""}${Math.abs(amt).toLocaleString(undefined, { maximumFractionDigits })}`
}

/**
 * Use Country currency to obtain Sign
 * @param {string} currency 
 * @returns 
 */
export const getCurrencySign = currency => {
    return getCurrencyDetails(currency)?.symbol ?? null;
}

/**
 * Use Country currency to obtain currency details
 * @param {string} currency 
 * @returns {object}
 */
export const getCurrencyDetails = currency => {
    return ({
        NGN: { symbol: "₦", name: "Naira", currency },
        USD: { symbol: "$", name: "US Dollar", currency },
        ZAR: { symbol: "R", name: "Rand", currency },
        GBP: { symbol: "£", name: "Pound", currency },
        HKD: { symbol: "HK$", name: "Hong Kong Dollar", currency },
        CAD: { symbol: "C$", name: "Canadian Dollar", currency },
        AUD: { symbol: "A$", name: "Australian Dollar", currency },
        EUR: { symbol: "€", name: "Euro", currency },
        PHP: { symbol: "₱", name: "Peso", currency },
        SEK: { symbol: "kr", name: "Krona", currency },
        BRL: { symbol: "R$", name: "Real", currency },
        SGD: { symbol: "S$", name: "Singapore Dollar", currency },
        KES: { symbol: "Ksh", name: "Shilling", currency },
        GHS: { symbol: "GH₵", name: "Cedi", currency },
        ZMW: { symbol: "ZK", name: "Kwacha", currency },
        UGX: { symbol: "USh", name: "Shilling", currency },
        RWF: { symbol: "R₣", name: "Franc", currency },
        XOF: { symbol: "CFA", name: "CFA Franc", currency },
        TZS: { symbol: "TSh", name: "Shilling", currency },
    })[currency?.toUpperCase()] ?? null;
}

// /**
//  * Use Country currency to obtain currency details
//  * @param {string} currency 
//  * @returns {object}
//  */
// export const getCurrencyDetails = currency => {
//     return ({
//         NGN: { symbol: "₦", name: "Nigerian Naira", currency },
//         USD: { symbol: "$", name: "US Dollar", currency },
//         ZAR: { symbol: "R", name: "South African Rand", currency },
//         GBP: { symbol: "£", name: "British Pound Sterling", currency },
//         HKD: { symbol: "HK$", name: "Hong Kong Dollar", currency },
//         CAD: { symbol: "C$", name: "Canadian Dollar", currency },
//         AUD: { symbol: "A$", name: "Australian Dollar", currency },
//         EUR: { symbol: "€", name: "Euro", currency },
//         PHP: { symbol: "₱", name: "Philippine Peso", currency },
//         SEK: { symbol: "kr", name: "Swedish Krona", currency },
//         BRL: { symbol: "R$", name: "Brazilian Real", currency },
//         SGD: { symbol: "S$", name: "Singapore Dollar", currency },
//         KES: { symbol: "Ksh", name: "Kenyan Shilling", currency },
//         GHS: { symbol: "GH₵", name: "Ghanaian Cedi", currency },
//         ZMW: { symbol: "ZK", name: "Zambian Kwacha", currency },
//         UGX: { symbol: "USh", name: "Ugandan Shilling", currency },
//         RWF: { symbol: "R₣", name: "Rwandan Franc", currency },
//         XOF: { symbol: "CFA", name: "West African CFA Franc", currency },
//         TZS: { symbol: "TSh", name: "Tanzanian Shilling", currency },
//     })[currency?.toUpperCase()] ?? null;
// }

export const convertToNumber = val => {
    const value = val?.toLocaleString(undefined, { maximumFractionDigits: 10 })?.replace(/[,]/g, '');
    return parseFloat(value) ? parseFloat(value) : 0;
}

export const formatNumber = val => {
    return val ? convertToNumber(val)?.toLocaleString() : val;
}


/* Extract Error Message */
export const errorMessage = error => {

    if (error?.response?.data?.error) {
        return error.response.data.error;
    }
    else if (error.response?.data?.data?.errors && !Array.isArray(error.response.data.data.errors)) {
        let message = "";
        for (let key in error.response.data.data.errors) {
            const val = error.response.data.data.errors[key];
            message += `${Array.isArray(val) ? val[0] : val} `;
        }
        return message;
    }
    else if (error.response && typeof (error.response.data.message) === "object") {
        let message = "";
        for (let key in error.response.data.message) {
            message += `${error.response.data.message[key]} `;
        }
        return message;
    }
    else if (error.response && typeof (error.response.data.message) === "string") {
        return error.response.data.message;
    }
    else if (Array.isArray(error.data?.errors)) {
        let message = "";
        for (const err of error.data.errors) {
            message += `${err.message ?? ""} `;
        }
        return message;
    }
    else if (error.message) {
        return error.message;
    }

    return `${error}`;
}

/* Transaction type image */
export const transactionImage = type => {
    const img = { withdraw: "img-41.jpg", dividend: "img-6.jpg", deposit: "img-54.jpg", buy: "img-60.jpg", sell: "img-18.jpg" }
    return `/media/stock-600x400/${img[type?.toLowerCase()] ?? "img-10.jpg"}`;
}

/* Transaction Type */
export const transactionCat = type => {
    const cat = { withdraw: "DEBIT", deposit: "CREDIT", buy: "DEBIT", sell: "CREDIT" }
    return cat[type?.toLowerCase()] ?? "OTHERS";
}

/* Delay Execution logic */
export const debounce = function (callback, delay) {
    let timeout;
    return function (e) {
        e?.persist();
        clearTimeout(timeout);
        timeout = setTimeout(_ => callback(e), delay);
    }
}

/* Delay Execution logic version 2 */
export function debounce2(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

/* Mimic Axios Cancel Function Before Set */
export const initialCancelFunction = (msg = null) => { }

// Capitalize first character
export const ucFirst = (s, othersLower = true) => {
    return s && s[0].toUpperCase() + (othersLower ? s.slice(1)?.toLowerCase() : s.slice(1));
}

// Capitalize first character of each words
export const ucWords = (s, othersLower = true) => {
    return s && s.split(/[_ ]/).map(item => ucFirst(item, othersLower)).join(" ");
}

// Confirm Kyc Requirement Completed
export const completeKyc = kyc => {

    if (!kyc) {
        return false;
    }

    // Fields Check
    for (const key in kyc) {
        const field = kyc[key];
        if (Array.isArray(kyc[key]) && kyc[key].length === 0) {
            return false;
        }
        if (key !== "riskProfile" && key !== "trustData" && field && typeof field === "object" && Object.keys(field).length === 0) {
            return false
        }
        if ((key === "firstName" || key === "lastName") && field === "") {
            return false;
        }
    }

    // Document Check
    const combineCheck = { "picture": false, "address": false }
    for (const doc of kyc.docs) {
        // Use 1 doc as Picture and Proof of Address as criteria
        if (doc.status?.toUpperCase() === "APPROVED" && doc.type?.toUpperCase() === "PICTURE_ID_PROOF_OF_ADDRESS") {
            return true;
        }

        // Use 2 docs Picture and Proof of Address as criteria
        if (doc.status?.toUpperCase() === "APPROVED" && doc.type?.toUpperCase() === "PICTURE_ID") {
            combineCheck.picture = true;
        }
        if (doc.status?.toUpperCase() === "APPROVED" && doc.type?.toUpperCase() === "PROOF_OF_ADDRESS") {
            combineCheck.address = true;
        }
    }

    return combineCheck.picture && combineCheck.address;
}

// Create deep object where applicable
export const formToDeepObject = (obj, objGroup = null) => {

    const reformat = {}
    for (let key in obj) {
        let innerKey = key.match(/\[(.*?)\]/);
        if (innerKey) {
            let outerKey = key.match(/(.*?)\[/)[1];
            if (!reformat[outerKey]) {
                reformat[outerKey] = {};
            }

            reformat[outerKey][innerKey[1]] = obj[key] === "true" ? true : obj[key];
        }
        else {
            reformat[key] = obj[key];
        }
    }

    // Group all object under objGroup name if applicable
    if (objGroup !== null) {
        reformat[objGroup] = {};
        for (let key in reformat) {
            if (key !== objGroup && !!reformat[key] && typeof reformat[key] === "object") {
                reformat[objGroup] = { ...reformat[objGroup], [key]: reformat[key] };
                delete reformat[key];
            }
        }
    }

    return reformat
}

// User Permission check
export const permissionAllow = (user, perm, permissionType = "read") => {
    return user?.adminLevel === 2 || !!user?.permissions?.[perm?.name]?.[permissionType]
}

// Return display Class based on permission
export const permissionCssClass = (user, perm, permissionType = "read") => {
    return permissionAllow(user, perm, permissionType) ? "" : "d-none";
}


/* Indicate Differences */
export const diffCheck = (old, latest) => {
    if (old === latest) {
        return latest;
    }
    else if (latest) {
        return <> <strike>{old}</strike> {old ? <> &rarr;</> : ""} <span className="text-primary">{latest ?? ""}</span></>;
    }
    else {
        return old ?? "";
    }
}



/**
 * Form Validation Helper
 * @param {form} form form element
 * @param {object} validState Current validation state
 * @returns {object} New Validation State
 */
export const formValidator = (form, validState) => {
    const fieldList = form.querySelectorAll('input, select, textarea');
    const fields = {};
    const fieldsBool = [];

    for (const field of fieldList) {
        const readOnlyType = field.readOnly && field.required;
        if (validState.validated || !!field.value || readOnlyType) {
            fields[field.name] = readOnlyType ? !!field.value : field.checkValidity();
            fieldsBool.push(fields[field.name]);
        }
    }


    const updateState = { ...validState, fields: { ...validState.fields, ...fields } }
    if (!updateState.validated && form.checkValidity() && !fieldsBool.some(opt => !opt)) {
        updateState.validated = true;
    }

    return updateState;
}


/**
 * Check Current validity status of the current field
 * @param {object} validObj Current validation object
 * @param {string} fieldName The field 
 * @returns {boolean} valid or not
 */
export const isValid = (validObj, fieldName) => {

    if (!validObj.validated) {
        return true;
    }

    if (validObj.fields[fieldName] === true) {
        return true;
    }

    return false;
}

/**
 * Convert funding sources comma separated list to corresponding values
 * @param {string} fundingSources 
 * @returns {Array} funding Sources value list
 */
export const fundingSourcesHelper = fundingSources => {
    const sourceArr = fundingSources.split(", ");

    const sourceObj = {
        "Employment": "EMP", "Gift": "GIFT", "Trust Fund": "TRUST", "Investment": "INVESTMENT", "Savings": "SAVINGS", "Retire": "RETIRE",
        "Gambling": "GAMBLING", "Legal": "LEGAL", "Family": "FAMILY", "Rollover": "ROLLOVER", "Rent": "RENT", "Sale": "SALE", "Insurance": "INSURANCE", "Unemployed": "UNEMP"
    }
    // Get corresponding value and remove undefines
    return sourceArr.map(src => sourceObj[src]).filter(src => !!src);
}

/**
 * Generate Random string
 * @returns {string}
 */
export const randomString = _ => {
    return (Math.random() + 1).toString(36).substring(2);
}

/**
 * Convert base64 file to file object
 * @param {string} dataurl base64 image 
 * @param {*} filename name
 */
export const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

/**
 * Copy Text to Clip Board
 * @param {string} text 
 */
export const copyToClipBoard = text => {
    const el = document.createElement('input');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    toastMessage("info", "Copied to Clipboard");
}

/**
 * Get GET parameter value from URL.
 * @param {string} paramName Parameter name.
 * @returns {string}
 */
export const getURLParam = paramName => {
    let searchString = window.location.search.substring(1),
        i, val, params = searchString.split("&");

    for (i = 0; i < params.length; i++) {
        val = params[i].split("=");
        if (val[0] === paramName) {
            return unescape(val[1]);
        }
    }

    return null;
}

/**
 *  Get current redirect Url if available
 * @returns {string} 
 */
export const getRedirectUrl = _ => {
    const redirectUrl = getURLParam("redirect_to")
    return redirectUrl ? window.atob(redirectUrl) : "";
}

/**
 * Get User IP
 * @returns {Promise} ip address
 */
export const getPublicIP = async _ => {
    try {
        const ip = await publicIp.v4();
        return ip;
    }
    catch (e) {
        console.log(e);
        return "197.210.47.80"; // default fall back
    }
}

/**
 * Use currency to get country code
 * @param {string} currency of the country
 * @returns country code
 */
export const getCountryCode = currency => {
    const countries = { NGN: "NG", USD: "US", ZAR: "SA", GBP: "UK", HKD: "HK", CAD: "CA", AUD: "AU", EUR: "EU", PHP: "P", SEK: "SE", BRL: "BR", SGD: "SG" }
    return countries[currency] ?? "";
}

/**
 * Get Provider for corressponding partner
 * @param {string} partner account partner 
 * @returns peovider
 */
export const getProvider = partner => {

    if (!partner) return null;
    if (partner.toLowerCase().search("ngn") >= 0) return "zanibal";
    if (partner.toLowerCase().search("usd") >= 0) return "alpaca";
    if (partner.toLowerCase().search("dw") >= 0) return "dw";
    return partner;
}

/**
 * Truncate long Asset Name
 * @param {string} name of asset 
 * @returns truncated version
 */
export const truncateAssetName = name => {
    if (!name) return null;
    return `${name?.substr(0, 16)}${name.length > 16 ? ".." : ""}`;
}

/**
 * Truncate long News Title
 * @param {string} title of news 
 * @returns truncated version
 */
export const truncateString = (title, len = 150) => {
    if (!title) return null;
    return `${title?.substr(0, len)}${title.length > len ? ".." : ""}`;
}

/**
 * Checks that there is no value in object
 * @param {Object} obj 
 * @returns {Boolean}
 */
export const checkObjectNotExist = obj => {
    if (!obj) return true
    return Object.keys(obj).length === 0 && obj.constructor === Object
}

/**
 * Get start and ends value in seconds for specified days
 * @param {integer} days 
 * @returns {object} start and end in seconds or milliseconds
 */
export const getStartAndEndSeconds = (days = 14, milli = false) => {
    // Date range from now to specified days back
    const end = getTodayInSeconds(milli);
    const start = getDaysBackInSeconds(days, milli)
    return { start, end };
}

/**
 * Get Current time in seconds
 * @returns {integer} seconds or milliseconds
 */
export const getTodayInSeconds = (milli = false) => {
    const divisor = milli ? 1 : 1000;
    return Math.round(new Date().getTime() / divisor);    // now
}

/**
 * 
 * @param {integer} days 
 * @returns {integer} seconds or milliseconds
 */
export const getDaysBackInSeconds = (days = 14, milli = false) => {
    const dt = new Date();
    dt.setHours(dt.getHours() - (24 * days));
    const divisor = milli ? 1 : 1000;
    return Math.round(dt.getTime() / divisor);  // For the specified number of days ago
}

/**
 * Format Number as
 * Trillion T
 * Billion B
 * Million M
 * @param {number} amount 
 */
export const formatTrillBillMillion = amount => {

    if ((amount / 1000000000000) > 1) {
        return `${(amount / 1000000000000).toFixed(2)}T`
    }
    if ((amount / 1000000000) > 1) {
        return `${(amount / 1000000000).toFixed(2)}B`
    }
    if ((amount / 1000000) > 1) {
        return `${(amount / 1000000).toFixed(2)}M`
    }

    return formatAmount("", amount);

}

/**
 * Get Time Passed
 * @param {string} dt date 
 * @returns {string} elapsed time
 */
export const getElapseTime = dt => {

    if (!dt) return null;

    const now = new Date();
    const otherDate = new Date(formatDate(dt));

    const diff = now.getTime() - otherDate.getTime();
    const msec = diff;

    // Years
    const yy = Math.floor(msec / 1000 / 60 / 60 / 24 / 30 / 12);
    if (yy > 0) {
        return `${yy} year${yy > 1 ? "s" : ""} ago`;
    }
    // Months
    const mn = Math.floor(msec / 1000 / 60 / 60 / 24 / 30);
    if (mn > 0) {
        return `${mn} month${mn > 1 ? "s" : ""} ago`;
    }
    // Days
    const dd = Math.floor(msec / 1000 / 60 / 60 / 24);
    if (dd > 0) {
        return `${dd} day${dd > 1 ? "s" : ""} ago`;
    }
    // Hours
    const hh = Math.floor(msec / 1000 / 60 / 60);
    if (hh > 0) {
        return `${hh} hour${hh > 1 ? "s" : ""} ago`;
    }
    // Minutes
    const mm = Math.floor(msec / 1000 / 60);
    if (mm > 0) {
        return `${mm} minute${mm > 1 ? "s" : ""} ago`;
    }

    return "Just now";
}

/**
 * Compare two dates
 * @param {string} date1 first date 
 * @param {string} date2 second date 
 * @param {boolean} asc  order
 * @returns {number} comparism
 */
export const compareDates = (date1, date2, asc = true) => {

    if (!date1 || !date2) return null;

    const firstDate = new Date(formatDate(date1));
    const secondDate = new Date(formatDate(date2));

    if (firstDate > secondDate) return asc ? 1 : -1;
    else if (firstDate < secondDate) return asc ? -1 : 1;
    else return 0;
}

/**
 * Get User disclosuresData data 
 * @param {string} firstName 
 * @param {string} lastName 
 * @returns JSON
 */
export const getDisclosuresData = async (firstName, lastName) => {

    const ipAddress = await getPublicIP();
    return {
        "termsOfUse": true,
        "customerAgreement": true,
        "iraAgreement": true,
        "marketDataAgreement": true,
        "marginAgreement": true,
        "accountAgreement": true,
        "rule14b": true,
        "privacyPolicy": true,
        "dataSharing": true,
        "date": new Date().toISOString(),
        ipAddress,
        "signedBy": `${firstName} ${lastName}`
    };
}

/**
 * Calculate Age from date of birth
 * @param {string} dateString Date of birth
 * @returns 
 */
export const getAge = dateString => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

/**
 * Convert Date Number to String
 * @param {integer} day 
 * @param {integer} month 
 * @param {integer} year 
 * @returns {string} Date String
 */
export const makeDateString = (day, month, year) => {

    if (!day || !month || !year) return null;

    month = month >= 10 ? month : `0${month}`;
    day = day >= 10 ? day : `0${day}`;
    return `${year}-${month}-${day}`;
}

/**
 * Convert Address data to String
 * @param {object} addressData 
 * @returns {string} address
 */
export const getAddress = (addressData) => {
    if (addressData) {
        const country = supportedCountries.find(item => item.iso === addressData?.country);
        return `${addressData?.street1 ? addressData?.street1 + "," : ""}  ${addressData?.city ? addressData?.city + "," : ""}  ${(addressData?.province && addressData.province !== " null") ? addressData?.province + "," : " "}  ${country ? country.name : ""}`;
    }
    return " ";
}

/**
 * Confirm if user submitted all required data
 * @param {object} profile 
 * @returns {boolean}
 */
export const profileCompleted = profile => {
    return profile.document?.some(item => ((item.type === "PROOF_OF_ADDRESS" || item.type === "PICTURE_ID_PROOF_OF_ADDRESS") && item.status !== "REJECTED")) && (profile.kyc.nationality === "NGA" || !!profile.riskProfile);
}

/**
 * Compute Portfolio Value
 * @param {object} portfolio 
 * @returns 
 */
export const getPortfolioValue = (portfolio, withCash = true) => {
    let investment;
    if (typeof portfolio?.realTime?.now === "number") {
        investment = portfolio.realTime?.now;
    }
    else {
        investment = (portfolio?.positions?.reduce((acc, item) => acc + item.equityBalance, 0) ?? 0);
    }
    return (withCash ? (portfolio?.cashBalance ?? 0) : 0) + investment;
}

/**
 *  Calculate Today Return
 * @param {object} portfolio 
 * @returns 
 */
export const getTodayReturn = portfolio => {

    if (typeof (portfolio?.realTime?.nowDiff) === "number" && typeof (portfolio?.realTime?.nowDiffPer) === "number") {
        return { todayReturn: portfolio.realTime?.nowDiff, todayReturnPercent: portfolio.realTime?.nowDiffPer };
    }
    let todayReturn = 0;
    let todayReturnPercent = 0;
    const today = new Date().getDate();
    const month = new Date().getMonth();
    const year = new Date().getFullYear()
    const todayPerformance = [...(portfolio?.performance ?? [])]?.reverse().find(item => new Date(item.time).getFullYear() === year && new Date(item.time).getMonth() === month && new Date(item.time).getDate() === today);
    const firstValue = todayPerformance?.value ?? 0;
    const equityBalance = getPortfolioValue(portfolio, false);
    if (firstValue > 0 && equityBalance > 0) {
        todayReturn = toTwoDeciPlaces(equityBalance - firstValue);
        todayReturnPercent = (todayReturn / firstValue) * 100;
    }

    return { todayReturn, todayReturnPercent };
}

/**
 * Calculate Total Return
 * @param {object} portfolio 
 * @returns 
 */
export const getTotalReturn = portfolio => {

    if (typeof portfolio?.realTime?.costDiff === "number" && typeof portfolio?.realTime?.costDiffPer === "number") {
        return { totalReturn: portfolio.realTime?.costDiff, totalReturnPercent: portfolio.realTime?.costDiffPer };
    }
    const equityBalance = getPortfolioValue(portfolio, false);
    const costBasis = portfolio?.positions?.reduce((acc, item) => acc + item.costBasis, 0) ?? 0;
    const totalReturn = toTwoDeciPlaces(equityBalance - costBasis);
    const totalDivisor = costBasis === 0 ? 1 : costBasis;
    const totalReturnPercent = (totalReturn / totalDivisor) * 100;

    return { totalReturn, totalReturnPercent };
}

/**
 * Get First Day of current Quarter 
 * @returns 
 */
export const getQuarterFirstDay = _ => {
    const d = new Date()
    const m = d.getMonth() - d.getMonth() % 3;
    return new Date(d.getFullYear(), m, 1, 0, 0, 0);
}

/**
 * Check if asset is supported by one of the providers
 * @param {string} provider 
 * @param {object} asset 
 */
export const isSupportByProvider = (provider, asset) => {
    const providerArr = provider?.toLowerCase()?.split(",") ?? [];
    const assetProviders = new Set(asset?.providers?.map(item => item.provider?.toLowerCase()));
    for (const prov of providerArr) {
        if (assetProviders.has(prov)) return true;
    }
    return false;
}

/**
 * Check if KYC is verify with Strict
 * @param {object} profile 
 * @returns 
 */
export const isKYCVerifiedStrict = profile => {
    return (profile.kyc?.status === "verified" && !!profile.kyc.addressData && profile.document?.some(item => ((item.type === "PROOF_OF_ADDRESS" || item.type === "PICTURE_ID_PROOF_OF_ADDRESS") && item.status === "APPROVED")))
}

/**
 * Check if KYC is verify for level 1 users
 * @param {object} profile 
 * @returns 
 */
export const isKYCVerifiedLevel1 = profile => {
    let b = (
    !!profile.kyc?.personalData &&
    !!profile.kyc?.investmentData &&
    // !!profile.kyc?.identificationData &&
    !!profile.kyc?.complianceData 
    && profile.document?.some(item => ((item.type === "PICTURE_ID" || item.type === "PICTURE_ID_PROOF_OF_ADDRESS" ) && item.status === "APPROVED"))
    )
    
    return b;
}
 
/**
 * Check if KYC is verify
 * @param {object} profile 
 * @returns 
 */
export const isKYCVerified = profile => {
    return profile.kyc?.status === "verified"
}

/**
 * Round Number to two decimal places
 * @param {double} numb 
 * @returns 
 */
export const toTwoDeciPlaces = numb => {
    return Math.round((numb + Number.EPSILON) * 100) / 100;
}

/**
 * Web Socket Host for current App
 */
export const getWebSocketHost = _ => {
    const host = window.location.hostname
    if (host === "localhost" || host.search("trovefinance.io") >= 0) {
        return "stream.trovefinance.io";
    }
    return "stream.trovefinance.net";
}

/**
 * Check ID document for Approval, Pending, Rejection, and Incomplete
 * @param {array} document 
 * @param {string} type 
 * @returns 
 */
export const checkId = document => {
    let doc = document?.find(item => ((item.type === "PICTURE_ID" || item.type === "PICTURE_ID_PROOF_OF_ADDRESS") && item.status === "APPROVED"));
    if (doc) return ({ status: "Completed" });

    doc = document?.find(item => ((item.type === "PICTURE_ID" || item.type === "PICTURE_ID_PROOF_OF_ADDRESS") && item.status === "PENDING"));
    if (doc) return ({ status: "Pending", text: "Waiting for Approval" });

    doc = document?.find(item => ((item.type === "PICTURE_ID" || item.type === "PICTURE_ID_PROOF_OF_ADDRESS") && item.status === "REJECTED"));
    if (doc) return ({ status: "Rejected", text: ucFirst(doc.rejectionReason) });

    return ({ status: "Incomplete", text: "Upload ID document" });
}

/**
 * 
 * Check Document for Approval, Pending, Rejection, and Incomplete
 * @param {array} document 
 * @returns 
 */
export const checkDoc = (document, type) => {
    let doc = document?.find(item => ((item.type === type) && item.status === "APPROVED"));
    if (doc) return ({ status: "Completed" });

    doc = document?.find(item => ((item.type === type) && item.status === "PENDING"));
    if (doc) return ({ status: "Pending", text: "Waiting for Approval" });

    doc = document?.find(item => ((item.type === type) && item.status === "REJECTED"));
    if (doc) return ({ status: "Rejected", text: ucFirst(doc.rejectionReason) });

    return ({ status: "Incomplete", text: `Upload ${type?.toLowerCase().replace(/_/g, " ")}` });
}

/**
 * Compute Fee For the Asset
 * @param {object} tradeData 
 * @param {string} mode 
 * @param {string} nationality 
 * @returns 
 */
export const getFee = (tradeData, mode, nationality) => {

    if (mode === "demo") {
        return 0;
    }

    const key = nationality === "NGA" ? "ngn" : "usd";
    const feeObj = FEES[key]?.find(item => item.currency === tradeData.currency)?.[tradeData.side.toLowerCase()];
    // Fee is zero if not available
    if (!feeObj) {
        return 0;
    }

    let fee = feeObj.flat + ((tradeData.amount * feeObj.percent) / 100);   // Compute fee
    fee = fee < feeObj.min ? feeObj.min : fee;  // Minimum Check
    fee = fee > feeObj.max ? feeObj.max : fee;  // Maximum Check
    // Negative for sell, positive for buy
    fee = tradeData.side === "BUY" ? fee : (-1 * fee);

    return toTwoDeciPlaces(fee);
}

/**
 * Convert Object To Array
 * @param {object} obj 
 * @returns {Array}
 */
export const objToArray = obj => {
    const arr = [];
    for (const key in obj) {
        arr.push([key, obj[key]]);
    }
    return arr;
}

export const getTimeDiff = (dt, seconds = false) => {

    if (!dt) return null;
    const datetime = new Date(dt).getTime();
    const now = new Date().getTime();

    let millSecDiff;
    if (datetime < now) {
        millSecDiff = now - datetime;
    }
    else {
        millSecDiff = datetime - now;
    }

    let res = "";

    // Days
    const dd = Math.floor(millSecDiff / 1000 / 60 / 60 / 24);
    if (dd > 0) {
        millSecDiff -= (dd * 1000 * 60 * 60 * 24);
        res += `${dd} day${dd > 1 ? "s" : ""} `;
    }
    // Hours
    const hh = Math.floor(millSecDiff / 1000 / 60 / 60);
    if (hh > 0) {
        millSecDiff -= (hh * 1000 * 60 * 60);
        res += `${hh} hour${hh > 1 ? "s" : ""} `
    }
    // Minutes
    const mm = Math.floor(millSecDiff / 1000 / 60);
    if (mm > 0) {
        millSecDiff -= (mm * 1000 * 60);
        res += `${mm} minute${mm > 1 ? "s" : ""} `
    }
    // Seconds
    if (seconds) {
        const ss = Math.floor(millSecDiff / 1000);
        res += `${ss} minute${ss > 1 ? "s" : ""} `
    }

    return res.trim();
}

/**
 * Get ID Type
 * @param {String} name 
 * @returns idType
 */
export const getIdType = name => {
    if (name === "International Passport") return "INTERNATIONAL_PASSPORT";
    if (name === "National ID Card") return "NATIONAL_ID";
    if (name === "NIMC / NIN Slip") return "NIN_NG";
    if (name === "Voter's Card") return "VOTERS_CARD"
    if (name === "Driver's License") return "DRIVERS_LICENSE";
    return null;
}

/**
 * Get Top Performer in portfolio positions
 * @param {Array} positions
 * @returns Symbol of Top Performer
 */
export const getTopPerformer = pos => {
    const positions = pos ?? [];
    let max = positions?.[0]?.asset?.quote?.priceChangePercent ?? 0.00;
    let topPerformer = positions?.[0]?.asset?.symbol ?? "";

    for (const position of positions) {
        if (position?.asset?.quote?.priceChangePercent > max) {
            max = position?.asset?.quote?.priceChangePercent;
            topPerformer = position?.asset?.symbol;
        }
    }

    return topPerformer;
}

/**
 * Check Transfer transactions and change type as appropriate
 * @param {object} trnx 
 * @returns 
 */
export const getDisplayType = trnx => {
    if (trnx.comment?.includes("Transfer from") || trnx.comment?.includes("Transfer to")) {
        return "Transfer";
    }
    return ucFirst(trnx.tranType ?? trnx.type);
}

/**
 * Get Corresponding flag name for the portfolio
 * @param {JSON} portfolio 
 * @returns {string} flag name
 */
export const getFlagName = portfolio => {
    if (portfolio?.partner === "alpaca_crypto") {
        return "CRYPTO";
    } if (portfolio?.partner === "trove_fixed_return_ngn") {
        return "FI";
    }
    return portfolio?.currency ?? "non";
}

/**
 * Get Corresponding portfolio name
 * @param {JSON} portfolio 
 * @returns {string} portfolio Name
 */
export const getPortfolioName = portfolio => {
    if (portfolio?.partner === "alpaca_crypto") {
        return "CRYPTO";
    } if (portfolio?.partner === "trove_fixed_return_ngn") {
        return "Fixed Income";
    }
    return getCountryCode(portfolio?.currency) + " Portfolio";
}
/**
 * Portfolio Type check
 * @param {object} port 
 * @returns {string}
 */
export const getPortfolioType = port => {
    if ((port?.partner?.includes("zanibal") || port?.partner?.includes("sigma")) || (port?.currency?.toUpperCase() === "NGN")) return "NG";
    if (port?.partner?.includes("crypto")) return "Crypto";
    if (port?.currency?.toUpperCase() === "USD") return "US";
    if (port?.partner?.includes("trove_fixed_return_ngn")) return "Fixed Income";
    return null;
}

// Get Status text color for orders
export const getStatusColor = status => {
    if (status.toLowerCase() === "failed" || status.toLowerCase()?.search("cancel") >= 0) {
        return "text-danger-1";
    }
    else if (status.toLowerCase() === "completed" || status.toLowerCase() === "executed" || status.toLowerCase()?.search("success") >= 0) {
        return "text-success-1";
    }
    else if (status.toLowerCase()?.search("pend") >= 0 || status.toLowerCase()?.search("process") >= 0) {
        return "text-yellow-2";
    }
    return "text-dark-2 dark:text-dark-1";
}

export const getDefaultProfileImage = gender => {
    if (gender && gender.toLowerCase() === "female") return "/assets/media/image/avatar-female.png";
    return "/assets/media/image/avatar-male.png";
}

/**
 * Get partner full nane
 * @param {string} partner 
 * @returns {string} partner full name
 */
export const getPartnerName = partner => {
    if (partner?.toLowerCase() === "dw") return "Drivewealth LLC";
    if (partner?.toLowerCase() === "alpaca") return "Alpaca LLC"; 
    if (partner?.toLowerCase() === "sigma") return "Sigma Securities Limited";
    if (partner?.toLowerCase() === "zanibal") return "ARM Securities Limited";
    return partner;
}


/**
 * Merge portfolios and prevent flash portfolio balance difference
 * @param {object} prev portfolios
 * @param {object} now portfolios
 * @returns merged portfolios
 */
export const mergePortfolios = (prev, now) => {
    if (!prev) return now;
    const prevHash = {};
    for (let port of prev) {
        prevHash[port.accountId] = port;
    }
    return now.map(item => ({ ...prevHash[item.accountId], ...item }));

}

/**
 * Retrieve Portfolio partners
 * @param {string} country 
 * @param {string} mode 
 * @returns {array}
 */
export const getPortfolioPartners = (country, mode = "live") => {
    if (country?.toUpperCase() === "US" && mode === "live") return ["alpaca", "dw"];
    if (country?.toUpperCase() === "NG" && mode === "live") return ["zanibal", "sigma"];
    if (country?.toUpperCase() === "RY" && mode === "live") return ["alpaca_crypto"];
    if (country?.toUpperCase() === "US" && mode === "demo") return ["paper_usd"];
    if (country?.toUpperCase() === "NG" && mode === "demo") return ["paper_ngn"];
    return [];
}


/**
 * Get Subscription using product
 * @param {string} product 
 * @returns {string}
 */
export const getSubscription = product => {
    return ({
        "market-insight-ngn": "Premium Plan",
        "market-insight-fn1": "Standard Plan",
        "market-insight-fn2": "Premium Plan"
    })[product] ?? "--";
}
// this is to check the nationality of a user / compare the provider in the portfolio and the assets.
export const getCategoryDetailsProviders = (nationality, portfolios) => {
    if (portfolios === 0 && nationality === "NGA" ? ["zanibal", "alpaca"] : ["alpaca", "alpaca_crypto"]);
    //let result = ((nationality === "NGA") ? "zanibal" : "alpaca_crypto");
    let result = ((nationality === "NGA") ? "zanibal,dw" : "alpaca,alpaca_crypto");
    
    let ownedProviders = [];
    for (let acc of portfolios) {
        if (ownedProviders.indexOf(acc.provider) === -1) {
            ownedProviders.push(acc.provider);
        }
    }
    if (ownedProviders.indexOf("trove_fixed_return_ngn") !== -1) {
        result += ",trove_fixed_return_ngn"
    } if (ownedProviders.indexOf("dw") !== -1) {
        result += ",dw";
    } 
    // else {
    //     result += ",alpaca";
    // }
    return result;
}

export const getMaturityDate = (days) => {
    const months = days / 30.44;
    const actualMonth = Math.round(months);
    const currentDate = new Date();
    const targetDate = new Date(currentDate.getFullYear(), currentDate.getMonth()+ actualMonth, currentDate.getDate());
    return targetDate.toDateString();
}