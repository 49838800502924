import {createSlice} from "@reduxjs/toolkit";

const initialGiftStocksState = {
  isLoading: false,
  notification: null,
  giftReceiver: null,
  receivedGiftedStocks: null,
  sentGiftedStocks: null,
  giftStockReview: null
};

export default createSlice({
  name: "giftStocks",
  initialState: initialGiftStocksState,
  reducers: {
    // GiftStocks Response Notification
    giftStocksNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
    // GiftStocks Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },

    
    /*****
     *  Gift Stock Reducers
     */

    // fetchGiftStocks
    giftStocksFetched: (state, action) => {
      state.isLoading = false;
      state.sentGiftedStocks = action.payload.promotion.sentGiftedStocks;
      state.receivedGiftedStocks = action.payload.promotion.receivedGiftedStocks;
    },

    // fetchGiftReceiver
    giftReceiverFetched: (state, action) => {
      state.isLoading = false;
      state.giftReceiver = action.payload;
    },

    // Giftstockreview
    giftStockReviewed: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
      state.giftStockReview = action.payload.data;
    },

    // giftStock
    stockGifted: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
      state.sentGiftedStocks = [action.payload.data, ...state.sentGiftedStocks];
    },

    // redeemGift
    giftRedeemed: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
      const gift = action.payload.data;
      state.receivedGiftedStocks = state.receivedGiftedStocks?.map(item => item.id === gift.id ? ({...item, ...gift, asset: item.asset}) : item);
    },

    // cancelGift
    giftCancelled: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
      const gift = action.payload.data;
      state.sentGiftedStocks = state.sentGiftedStocks?.map(item => item.id === gift.id ? ({...item, ...gift, asset: item.asset}) : item);
    },

    // updateNotifications
    notificationsUpdated: (state, action) => {
      state.notificationOptions = action.payload.allowedEvents;
    },
  }
});