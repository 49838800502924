import { createSlice } from "@reduxjs/toolkit";

const initialCardState = {
  isLoading: false,
  notification: null,
  cards: null,
  transactionHistory: null,
  cardStatus: null,
  card: null,
  historyListEndCheck: false,
};

export default createSlice({
  name: "card",
  initialState: initialCardState,
  reducers: {
    // Card Response Notification
    cardNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
    // Card Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },

    /*****
     *  Card Reducers
     */

    userCardChecked: (state, action) => {
      state.isLoading = false;
      state.cards = action.payload.cards.cards ?? [];
      state.cardStatus = action.payload.cards.cardUser ? action.payload.cards.cardUser.status : null;
    },

    cardDetailsFetched: (state, action) => {
      state.isLoading = false;
      state.card = action.payload.cards.card;
      state.historyListEndCheck = action.payload.cards.transactions.length < 20; // mark as list end if return asset is less than limit
      state.transactionHistory = action.payload.cards.transactions
    },

    moreTransactionHistoryFetched: (state, action) => {
      state.isLoading = false;
      state.historyListEndCheck = action.payload.cards.transactions.length < 20; // mark as list end if return asset is less than limit
      state.transactionHistory = [...state.transactionHistory, ...action.payload.cards.transactions];
    },

    userCardStateChanged: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
      state.card = { ...state.card, ...action.payload };
    },

  }
});