import {configureStore} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import {rootReducer, rootSaga} from "./rootReducer";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER} from 'redux-persist'
import localStorage from 'redux-persist/lib/storage';


// saga middleware for side effects
const sagaMiddleware = createSagaMiddleware();

// Redux persistor

const persistConfig = {
  key: 'trove-web-app',
  version: 1,
  storage: localStorage,
  whitelist: ['auth', 'profile'] // only auth and profile will be persisted
}

const persistedReducer = persistReducer(persistConfig, rootReducer)


const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
                                                              serializableCheck: {
                                                                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, "auth/authenticated"],
                                                              },
                                                            }).concat(sagaMiddleware)
});

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);


export default store;
