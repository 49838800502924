import { ASSET_QUERY_FIELDS } from "../../common/data/constants";
import { getDaysBackInSeconds, getTodayInSeconds } from "../../common/util/Helpers";

/*****
 * 
 *  Portfolio GraphQl Query
 */

export const portfolioQuery = _ => JSON.stringify({
  query: `{
                                        account{
                                          completeUserAccounts(account: {}){
                                            accountId,
                                            accountManagementType,
                                            accountNumber,
                                            availableForWithdrawal,
                                            cashBalance,
                                            created,
                                            currency,
                                            failureReason,
                                            lockedBalance,
                                            name,
                                            partner,
                                            partnerId,
                                            portfolio,
                                            provider,
                                            pendingSettlement{ amount, endDate, startDate },
                                            pendingSettlementAmount,
                                            riaPortfolioID,
                                            riaProductID,
                                            riaUserID,
                                            status,
                                            tradingPower,
                                            updated,
                                            userGroup,
                                            userId,
                                            userType,
                                            goodFaithViolation,
                                            extendedHoursEnabled,
                                            meta
                                          }
                                        }
                                        marketInsight{marketStatus}
                                        payment{
                                          exchangeRates(exchange: {}){
                                            fromCurrency,
                                            rate,
                                            toCurrency
                                          }
                                        }
                                        
                                        cards{
                                          cards(card: {}){
                                            balance
                                          }
                                        }
                                    }`,
  variables: {
  }
})

export const portfolioDetailsQuery = accountIds => JSON.stringify({
  query: `{
                                          
                                        ${accountIds.reduce((acc, id) => acc + ` acc_${id.replace(/[-.]/g, "_")}: account{
                                          allPositions(position: 
                                            {accountId: "${id}"}){
                                              accountId, 
                                              accountNumber, 
                                              asset{
                                               currency, 
                                               country, 
                                               id, 
                                               imageUrl, 
                                               name, 
                                               quote{price, priceChangePercent, priceChange, exchange}, symbol}, 
                                               costBasis, 
                                               created,
                                               equityBalance, 
                                               instrumentCurrency, 
                                               instrumentId, 
                                               lockedQuantity, 
                                               name, 
                                               price, 
                                               symbol, 
                                               totalQuantity,
                                               updated,
                                               userGroup, 
                                               userId,
                                              },
                                            }, ` 
                                            , 
                                            "")} 

                                        ${accountIds.reduce((acc, id) => acc + ` perf_${id.replace(/[-.]/g, "_")}:  portfolioPerformance { performance(request: { account: {accountId: "${id}"}, asset: { symbol: "__networth__"}, pagination: {limit: 1000}, end: ${getTodayInSeconds(true)}, start: ${getDaysBackInSeconds(366, true)}}) {
                                          time, value } } ` , "")} 

                                        ${accountIds.reduce((acc, id) => acc + ` trade_${id.replace(/[-.]/g, "_")}: account{ statements(statement: {accountId: "${id}", endDate: "${new Date(getTodayInSeconds(true)).toISOString()}", startDate: "${new Date(getDaysBackInSeconds(732, true)).toISOString()}", statementType: TradeConfirmation}) }`, "")} 

                                        ${accountIds.reduce((acc, id) => acc + ` state_${id.replace(/[-.]/g, "_")}: account{ statements(statement: {accountId: "${id}", endDate: "${new Date(getTodayInSeconds(true)).toISOString()}", startDate: "${new Date(getDaysBackInSeconds(732, true)).toISOString()}", statementType: AccountStatement}) }`, "")} 
                                    }`,
  variables: {
  }
});

export const portfolioAssetHistoricalDataQuery = (assetIds, start, end) => JSON.stringify({
  query: `{
                                                    
                                                    ${assetIds.reduce((acc, id) => acc + ` ass_${id.replace(/-/g, "_")}: instrument { historicalData(asset: {id: "${id}"}, startDate: ${start}, endDate: ${end}, period: "1D"){ close, date }} `, "")} 
                                                  
                                              }`,
  variables: {
  }
});


export const marketStatusQuery = _ => JSON.stringify({
  query: `{
                                                  marketInsight{marketStatus}
                                              }`,
  variables: {
  }
});


export const portfolioTransactionsQuery = accountId => JSON.stringify({
  query: `{
                                                            account{
                                                              accountHistory(account: {accountId: "${accountId}"}){
                                                                autoId,
                                                                comment,
                                                                created,
                                                                failureReason,
                                                                historyId
                                                                tranAmount,
                                                                tranStatus,
                                                                tranTime,
                                                                tranType,
                                                                transactionType,
                                                                updated,
                                                              }
                                                            }
                                                            payment{
                                                              accountPaymentHistory(transaction: {accountId: "${accountId}"}){
                                                                amount,
                                                                createdAt,
                                                                updatedAt,
                                                                currency,
                                                                toCurrency,
                                                                description,
                                                                email,
                                                                gateway,
                                                                instantComment,
                                                                instantDeposit,
                                                                instantFee,
                                                                last4,
                                                                method,
                                                                processFee,
                                                                ref,
                                                                serviceFee,
                                                                status,
                                                                total,
                                                                type
                                                              }
                                                            }
                                                          }`,
  variables: {}
});

export const portfolioAssetRecommendationQuery = (accountId, riskScore) => JSON.stringify({
  query: `{
                                                                                marketInsight{
                                                                                  assetRecommendations(assetRecommendation: {accountId: "${accountId}", riskScore: ${riskScore}}, pagination: {limit: 5}){
                                                                                      ${ASSET_QUERY_FIELDS}
                                                                                    }
                                                                                  }
                                                                                }`,
  variables: {}
});

export const wireDepositAccountQuery = (partner, accountId) => JSON.stringify({
  query: `{
                                                        payment{
                                                          wireDepositAccount(wireAccount: {partner: ${partner}, accountId: "${accountId}"}){
                                                            accountNumber,
                                                            bankAddress,
                                                            bankName,
                                                            beneficiaryName,
                                                            beneficiaryAddress,
                                                            instructions,
                                                            meta,
                                                            partner,
                                                            routingNumber,
                                                            routingNumberWire,
                                                            swiftCode,
                                                            userGroup
                                                          }
                                                        }
                                                      }`,
  variables: {
  }
});
