import { ASSET_QUERY_FIELDS } from "../../common/data/constants";

/*****
 * 
 *  Dashboard GraphQl Query
 */

export const dashboardDataQuery = _ => JSON.stringify({
                                    query: `{
                                          marketInsight{
                                              news(countries: "US", limit: 40) {
                                                  title, summary, imageUrl, dateTime, url, source
                                                }
                                                topGainers(country: "US", limit: 12) {
                                                  ${ASSET_QUERY_FIELDS}
                                                }
                                                topLosers(country: "US", limit: 12) {
                                                  ${ASSET_QUERY_FIELDS}
                                                }
                                          }
                                          instrument {
                                            themes(includeAsset: false, pagination: {limit: 200}) {
                                              description, id, meta, name
                                            }
                                          }
                                      }`,
                                    variables: {}
                                  });

export const catAssetsQuery = (provider, catIds) => JSON.stringify({
                                      query: `{
                                          ${catIds.reduce((acc, id) => acc + ` ass_${id.replace(/[-.]/g, "_")}: instrument{ theme(theme: {id: "${id}"}, condition: {provider: "${provider}"}, pagination: {limit: 20}){assets{${ASSET_QUERY_FIELDS}}}} ` , "")} 
                                      }`,
                                      variables: {
                                      }
                                    });


export const loadMoreAssetsInCategoryQuery = (provider, catId, offset) => JSON.stringify({
                                                                  query: `{
                                                                    instrument{ theme(theme: {id: "${catId}"}, condition: {provider: "${provider}"}, pagination: {limit: 20, offset: ${offset}}){assets{${ASSET_QUERY_FIELDS}}}}
                                                                  }`,
                                                                  variables: {
                                                                  }
                                                                });


export const assetsHistoricalDataQuery = (assetIds, start, end) => JSON.stringify({
                                                                      query: `{
                                                                                  
                                                                                  ${assetIds.reduce((acc, id) => acc + ` ass_${id.replace(/-/g, "_")}: instrument { historicalData(asset: {id: "${id}"}, startDate: ${start}, endDate: ${end}, period: "1D"){ close, date }} `, "")} 
                                                                                
                                                                            }`,
                                                                      variables: {
                                                                      }
                                                                    });
