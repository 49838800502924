import { createSlice, current } from "@reduxjs/toolkit";
import { convertUTCToLocalDate, mergePortfolios } from "../../common/util/Helpers";


const initialPortfolioState = {
  isLoading: false,
  notification: null,
  mode: sessionStorage.getItem("accountMode") ?? "live",
  activeAccountId: sessionStorage.getItem("activeAccountId"),
  hideBalance: !!sessionStorage.getItem("hideBalance"),
  showCreatePortModal: false,
  portfolios: null,
  marketStatus: {},
  exchangeRates: [],
  cardBalance: null,
  wireDepositAccount: null,
  instantDepositStatus: false
};

export default createSlice({
  name: "portfolio",
  initialState: initialPortfolioState,
  reducers: {
    // Portfolio Response Notification
    portfolioNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
    // Portfolio Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },

    /*****
     *  Portfolio Reducers
     */

    // fetchPortfolios
    portfoliosFetched: (state, action) => {
      state.isLoading = false;
      const updatedPortfolios = action.payload.account.completeUserAccounts?.sort((a, b) => a.currency.localeCompare(b.currency));
      state.portfolios = mergePortfolios(current(state).portfolios, updatedPortfolios);
      state.marketStatus = action.payload.marketInsight.marketStatus;
      state.exchangeRates = action.payload.payment.exchangeRates;
      state.cardBalance = action.payload.cards.cards?.[0]?.balance;
    },

    // switchPortfolio
    changeActiveAccountId: (state, action) => {
      state.showCreatePortModal = false;
      state.activeAccountId = action.payload;
    },

    // setHideBalance
    changeHideBalance: (state, action) => {
      state.hideBalance = action.payload;
    },

    // switchMode
    changeMode: (state, action) => {
      state.mode = action.payload;
    },

    // toggleCreatePortModal
    toggleModal: (state) => {
      state.showCreatePortModal = !state.showCreatePortModal;
    },

    // portfoliosFetchedSaga
    portfolioDetailsFetched: (state, action) => {
      state.isLoading = false;
      state.portfolios = state.portfolios?.map(item => ({ ...item, positions: action.payload[`acc_${item.accountId?.replace(/[-.]/g, "_")}`]?.allPositions ?? [], performance: action.payload[`perf_${item.accountId?.replace(/[-.]/g, "_")}`]?.performance ?? [], tradeConfirmation: action.payload[`trade_${item.accountId?.replace(/[-.]/g, "_")}`]?.statements?.reverse() ?? [], accountStatement: action.payload[`state_${item.accountId?.replace(/[-.]/g, "_")}`]?.statements?.reverse() ?? [] }));
    },

    // createPortfolio
    portfolioCreated: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
      state.activeAccountId = action.payload.accountId;
      state.portfolios = [...(state.portfolios ?? []), action.payload];
    },

    // fetchPortfolioAssetHistoricalData
    portfolioAssetHistoricalDataFetched: (state, action) => {
      state.isLoading = false;

      const { accountId, ...history } = action.payload;
      const positions = state.portfolios?.find(item => item.accountId === accountId)?.positions;

      for (let i = 0; i < positions.length; i++) {
        positions[i] = { ...positions[i], asset: { ...positions[i].asset, historicalData: history[`ass_${positions[i].asset.id?.replace(/-/g, "_")}`]?.historicalData ?? [] } }
      }
    },

    // 
    marketStatusFetched: (state, action) => {
      state.marketStatus = action.payload.marketInsight.marketStatus;
    },

    // fetchPortfolioTransactions
    portfolioTransactionsFetched: (state, action) => {
      state.isLoading = false;
      const { accountId, account, payment } = action.payload;
      const accountPaymentHistory = (payment.accountPaymentHistory ?? []).filter(item => item.status?.toLowerCase() !== "completed");
      const accountHistory = (account.accountHistory ?? []).filter(item => (item.tranStatus?.toLowerCase() !== "pending" && item.tranStatus?.toLowerCase() !== "processing"));
      const transactions = [...accountHistory, ...accountPaymentHistory];
      transactions.sort((a, b) => convertUTCToLocalDate(b.createdAt ?? b.created) - convertUTCToLocalDate(a.createdAt ?? a.created));
      state.portfolios = state.portfolios?.map(item => item.accountId === accountId ? ({ ...item, transactions }) : item);
    },

    // fetchPortfolioAssetRecommendation
    portfolioAssetRecommendationFetched: (state, action) => {
      state.isLoading = false;
      const { accountId, marketInsight: {assetRecommendations} } = action.payload;
      state.portfolios = state.portfolios?.map(item => item.accountId === accountId ? ({ ...item, assetRecommendations }) : item);
    },

    wireDepositAccountFetched: (state, action) => {
      state.isLoading = false;
      state.wireDepositAccount = action.payload.payment.wireDepositAccount ?? {};
      state.notification = action.payload.notification;
    },

    instantDepositStatusFetched: (state, action) => {
      state.isLoading = false;
      state.instantDepositStatus = action.payload.status;
    },

    // toggleExtendedHours
    extendedHoursToggled: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
      const {accountId, extendedHoursEnabled} = action.payload;
      state.portfolios = state.portfolios?.map(item => item.accountId === accountId ? ({ ...item, extendedHoursEnabled }) : item);
    },

    // newRealTimePortUpdate
    realTimePortUpdate: (state, action) => {
      const {accountId, balance:cashBalance, ...realTime} = action.payload ?? {};
      state.portfolios = state.portfolios?.map(item => item.accountId === accountId ? ({ ...item, cashBalance, realTime }) : item);
    }
  }
});

