import {createSlice} from "@reduxjs/toolkit";
import { getTheme } from "../../common/util/Helpers";

const initialSettingsState = {
  isLoading: false,
  notification: null,
  notificationOptions: null,
  theme: getTheme(),
  subscription: null
};

export default createSlice({
  name: "settings",
  initialState: initialSettingsState,
  reducers: {
    // Settings Response Notification
    settingsNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
    // Settings Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },

    
    /*****
     *  Settings Reducers
     */

    // fetchSettings
    settingsFetched: (state, action) => {
      state.isLoading = false;
      state.notificationOptions = action.payload.notification?.channels?.find(item => item.broker === "push");
    },

    
    // updateNotifications
    notificationsUpdated: (state, action) => {
      state.notificationOptions = action.payload.allowedEvents;
    },

    // setTheme
    changeTheme: (state, action) => {
      state.theme = action.payload;
    },

    /***
     * Subscriptions
     */

    // fetchSubscription
    subscriptionFetched: (state, action) => {
      state.isLoading = false;
      state.subscription = {...(action.payload.subscription?.subscriptions?.find(item => ["market-insight-ngn", "market-insight-fn1", "market-insight-fn2"].includes(item.product)) ?? {}), billings: action.payload.subscription?.subscriptionBillings ?? [] };
    },
    
    // createSubscription
    subscriptionCreated: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },

    // updateSubscription
    subscriptionUpdated: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
  }
});