import { Suspense } from "react"
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import LayoutSplashScreen from "./common/layout/layout-components/LayoutSplashScreen";
import { persistor } from "./redux/store";
import Routes from "./routes";
import { PersistGate } from 'redux-persist/integration/react';
import { IntercomProvider } from "react-use-intercom";

const App = ({store, basename}) => {
  return  <>
            {/* Provide Redux store  */}
            <Provider store={store}>
              {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
              <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
                <Suspense fallback={<LayoutSplashScreen />}>
                  <BrowserRouter basename={basename} >
                    <IntercomProvider appId={process.env.REACT_APP_INTERCOM_ID}>
                      <Routes/>
                    </IntercomProvider>
                  </BrowserRouter>
                </Suspense>
              </PersistGate>
            </Provider>
          </>
}

export default App;
