import axios from "axios";

/*****
 *  Auth Request
 */
export const refreshTokenReq = refreshToken => {
  return axios.post(`/refresh-token/${refreshToken}`, {});
}

export const verifyEmailReq = email => {
  return axios.post(`/verify-email/${email}`, {});
}

export const signUpReq = authData => {
  return axios.post(`/register`, { ...authData });
}

export const loginReq = (email, password) => {
  return axios.post("/login", { email, password });
}

export const sendDeviceOTPReq = (body) => {
  return axios.post("/activate-device", body);
}

export const activateDeviceReq = (otp, email) => {
  return axios.patch(`/activate-device?${new URLSearchParams({otp, email}).toString()}`, {});
}

export const forgotPasswordReq = (email) => {
  return axios.post("/reset-password", { email });
}

export const resetPasswordReq = ({password, ...data}) => {
  return axios.patch(`/reset-password?${new URLSearchParams(data).toString()}`, {password});
}

export const migrationStatusReq = email => {
  return axios.post("/migration-status", {email});
}