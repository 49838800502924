import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";
import { ucFirst } from "../common/util/Helpers";

// Lazy load Auth and Non Auth Section of the site
const NoAuthRoute = React.lazy(() => import("./NoAuthRoute"));
const AuthenticatedRoute = React.lazy(() => import('./AuthenticatedRoute')); 

const Routes = _ => {

    const { boot, shutdown } = useIntercom();
    const [email, userId, firstName, lastName] = useSelector(({ profile, auth }) => [profile?.email ?? auth?.onBoardEmail, profile?.userId ?? auth?.userId, profile?.firstName, profile?.lastName], shallowEqual);
    const authenticated = useSelector(({auth}) => auth?.authenticated);

    // Intercom Initialiser
    useEffect(_ => {
        const intercomUser = {};
        if (userId) intercomUser.userId = userId;
        if (email) intercomUser.email = email;
        if (firstName) intercomUser.name = `${ucFirst(firstName)} ${ucFirst(lastName)}`;
        boot(intercomUser);
        return shutdown;
    }, [boot, shutdown, email, userId, firstName, lastName])

    return authenticated ? <AuthenticatedRoute /> : <NoAuthRoute />
}

export default Routes;
