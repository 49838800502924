import axios from "axios";
import { watchlistAssetHistoricalDataQuery, watchlistQuery } from "./watchlistQuery";


/*****
 * 
 *  Watchlist Request
 */

export const fetchWatchlistsReq = _ => {
  return axios.post("/graphql", watchlistQuery());
}

export const createWatchlistReq = (watchlistData) => {
  return axios.post(`/instruments/watchlist`, { ...watchlistData });
}

export const updateWatchlistReq = (watchlistData, id) => {
  return axios.put(`/instruments/watchlist/${id}`, { ...watchlistData });
}

export const fetchWatchlistAssetHistoricalDataReq = (assetIds, start, end) => {
  return axios.post("/graphql", watchlistAssetHistoricalDataQuery(assetIds, start, end));
}

export const deleteWatchlistReq = id => {
  return axios.delete(`/instruments/watchlist/${id}`, {});
}

