import {createSlice} from "@reduxjs/toolkit";

const initialAuthState = {
  isLoading: false,
  appKey: null,
  token: null,
  refreshToken: null,
  notification: null,
  authenticated: false,
  otpVerified: false,
  onBoardEmail: null,
  nationality: null,
  migration: null,
  userId: null,
};

export default createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    // Auth Response Notification
    authNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
    // Auth Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },

    
    /*****
     *  Auth Reducers
     */

    // verifyEmail
    emailVerified: (state, action) => {
      state.isLoading = false;
      state.onBoardEmail = action.payload;
    },

    // user Authenticated
    authenticated: (state, action) => {
      state.isLoading = false; 
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      state.onBoardEmail = action.payload.email ?? state.onBoardEmail;
      state.nationality = action.payload.nationality ?? state.nationality;
      if (action.payload.userId) state.userId = action.payload.userId;
      if (action.payload.headers?.["app-key"]) state.appKey = action.payload.headers["app-key"];
      // Mark authenticated if status code is 200 on login or authenticated is returned as true
      state.authenticated = action.payload.authenticated ?? (action.payload.statusCode === 200);
      state.migration = (action.payload.migration ?? action.payload.migrated)  >= -2 && ({profileMig: 0, kycMig: 0, watchlistMig: 0, bankMig: 0, docsMig: 0, paperAccMig: 0, usAccMig: 0, ngAccMig : 0});
    },

    // forgotPassword
    passwordForgot: (state, action) => {
      state.isLoading = false;
      state.onBoardEmail = action.payload.email;
      state.notification = action.payload.notification
    },

    // migrationStatusSaga
    updateMigrationStatus: (state, action) => {
      state.migration = {...(state.migration ?? {}), ...action.payload};
    },

    // refreshTokenSaga
    tokenRefreshed: (state, action) => {
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      if (action.payload.userId) state.userId = action.payload.userId;
    },

    // activateDevice
    deviceActivated: (state, action) => {
      state.isLoading = false;
      state.otpVerified = true;
      state.authenticated = action.payload.authenticated;
    },
    
    // user Logout (See root reducer)
    loggedOut: _ => {}
  }
});