import { put, takeLatest, delay } from "@redux-saga/core/effects";
import { errorMessage } from "../../common/util/Helpers";
import { createPortfolioReq, fetchMarketStatusReq, fetchPortfolioDetailsReq } from "./portfolioCrud";
import portfolioSlice from "./portfolioSlice";


/**
 * Saga Portfolio Middleware (Side Effects)
 */

export default function* portfolioSaga() {

    /* Portfolio Fetched Side Effect */

    yield takeLatest(["portfolio/portfoliosFetched", "auth/loggedOut"], function* marketStatusSaga(action) {
        // Terminate on logout
        if (action?.type === "auth/loggedOut") return;
        
        // Fetch Market Status every 5 minutes
        try {
            yield delay(5 * 60 * 1000);    // 5 Minutes delay
            const {data: {data}} = yield fetchMarketStatusReq();
            yield put(portfolioSlice.actions.marketStatusFetched(data));
            yield marketStatusSaga();
        }
        catch(err) {
            console.log(err);
            yield delay(5 * 60 * 1000);    // 5 Minutes delay before retry
            yield marketStatusSaga();
        }
    });

    yield takeLatest("portfolio/portfoliosFetched", function* portfoliosFetchedSaga({payload}) {
        // Fetch Portfolio Details
        try {
            const accountIds = payload.account?.completeUserAccounts?.map(item => item.accountId) ?? [];
            if (accountIds?.length > 0){
                const {data: {data}} = yield fetchPortfolioDetailsReq(accountIds);
                yield put(portfolioSlice.actions.portfolioDetailsFetched(data));
            }
        }
        catch(err) {
            yield put(portfolioSlice.actions.portfolioNotification({ notification: {type: "error", message: errorMessage(err)}}));
        }
    });

    yield takeLatest("portfolio/portfoliosFetched", function* createDefaultPortfolioSaga({payload}) {
        
        if (!Array.isArray(payload.account?.completeUserAccounts)) return;
        try {
            const demoUsd = payload.account?.completeUserAccounts?.find(item => item.partner === "paper_usd");
            if (!demoUsd){
                const {data: {data}} = yield createPortfolioReq("paper_usd");
                yield put(portfolioSlice.actions.portfolioCreated({...data, notification: null}));
            }
        }
        catch(err) {
            yield put(portfolioSlice.actions.portfolioNotification({ notification: {type: "error", message: errorMessage(err)}}));
        }
    });

    yield takeLatest("portfolio/changeActiveAccountId", function* changeActiveAccountIdSaga({payload}){
        // Update / Delete Active Portfolio Account Id when change detected
        yield payload ? sessionStorage.setItem("activeAccountId", payload) : sessionStorage.removeItem("activeAccountId");
    });

    yield takeLatest("portfolio/changeMode", function* changeModeSaga({payload}){
        // Save User Mode (demo/live)
        yield sessionStorage.setItem("accountMode", payload);
    });

}
