import axios from "axios";
import { allAssetsQuery, assetGroupHistoricalDataQuery, assetsBySymbolQuery, instrumentAssetDetailsQuery, loadMoreAssetsQuery, marketInsightAssetDetailsQuery, ordersAssetDetailsQuery, portfolioPerformanceAssetDetailsQuery, searchAssetQuery, singleAssetHistoricalDataQuery, trendingRecentAssetsQuery } from "./assetsQuery";


/*****
 * 
 *  Assets Request
 */

export const fetchAllAssetsReq = provider => {
  return axios.post("/graphql", allAssetsQuery(provider));
}

export const loadMoreAssetsReq = (provider, group, offset) => {
  return axios.post("/graphql", loadMoreAssetsQuery(provider, group, offset));
}

export const fetchAssetGroupHistoricalDataReq = (assetIds, start, end) => {
  return axios.post("/graphql", assetGroupHistoricalDataQuery(assetIds, start, end));
}

export const fetchInstrumentAssetDetailsReq = (country, symbol, assetId) => {
  return axios.post("/graphql", instrumentAssetDetailsQuery(country, symbol, assetId));
}

export const fetchOrderAssetDetailsReq = (symbol, accountId) => {
  return axios.post("/graphql", ordersAssetDetailsQuery(symbol, accountId));
}

export const fetchMarketInsightAssetDetailsReq = (symbol, country, subScribed) => {
  return axios.post("/graphql", marketInsightAssetDetailsQuery(symbol, country, subScribed));
}

export const fetchPortfolioPerformanceAssetDetailsReq = (country, symbol, accountId) => {
  return axios.post("/graphql", portfolioPerformanceAssetDetailsQuery(country, symbol, accountId));
}

export const fetchAssetBySymbol = (country, symbols) => {
  return axios.post("/graphql", assetsBySymbolQuery(country, symbols));
}

export const fetchSingleAssetHistoricalDataReq = (id, start, end) => {
  return axios.post("/graphql", singleAssetHistoricalDataQuery(id, start, end));
}

export const createAssetOrderReviewReq = order => {
  return axios.post("/orders/create/review", { ...order });
}

export const createAssetOrderReq = order => {
  return axios.post("/orders/create", { ...order });
}

export const fetchTrendingRecentAssetsReq = (provider, assetIds) => {
  return axios.post("/graphql", trendingRecentAssetsQuery(provider, assetIds));
}

export const fetchSearchAssetReq = (provider, query) => {
  return axios.post("/graphql", searchAssetQuery(provider, query));
}

export const generateWebsocketTokenReq = _ => {
  return axios.post("/websocket/auth");
}