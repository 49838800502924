
import { ASSET_QUERY_FIELDS } from "../../common/data/constants";
import { getDaysBackInSeconds, getTodayInSeconds } from "../../common/util/Helpers";

/*****
 * 
 *  Assets GraphQl Query
 */

// Query Calleable functions in object
const queryFunc = {

  ngAsset: (provider, offset = 0) => `instrument {
                      assets(condition:{status: ACTIVE, market: "NSE", provider: "${provider}"}, pagination: {limit: 20, offset: ${offset}}) {
                          ${ASSET_QUERY_FIELDS}
                      }
                    }`,
  frAsset: (provider, offset = 0) => `instrument {
                      assets(condition:{status: ACTIVE, country: "FR_NGA", provider: "${provider}"}, pagination: {limit: 20, offset: ${offset}}) {
                          ${ASSET_QUERY_FIELDS}
                      }
                    }`,

  foreignAsset: (provider, offset = 0) => `instrument {
                      assets(condition:{status: ACTIVE, country: "US", type: EQUITY, provider: "${provider}"}, pagination: {limit: 20, offset: ${offset}}) {
                        ${ASSET_QUERY_FIELDS}
                      }
                    }`,

  etfAsset: (provider, offset = 0) => `instrument {
                      assets(condition:{status: ACTIVE, country: "US", type: ETF, provider: "${provider}"}, pagination: {limit: 20, offset: ${offset}}) {
                        ${ASSET_QUERY_FIELDS}
                      }
                    }`,

  cryptoAsset: (provider, offset = 0) => `instrument {
                      assets(condition:{status: ACTIVE, country: "RY", type: CRYPTO, provider: "${provider}"}, pagination: {limit: 20, offset: ${offset}}) {
                        ${ASSET_QUERY_FIELDS}
                      }
                    }`
}

export const allAssetsQuery = provider => JSON.stringify({
  query: `{
                                          ngAsset: ${queryFunc.ngAsset(provider)}
                                          foreignAsset: ${queryFunc.foreignAsset(provider)}
                                          etfAsset: ${queryFunc.etfAsset(provider)}
                                          cryptoAsset: ${queryFunc.cryptoAsset(provider)}
                                          frAsset: ${queryFunc.frAsset(provider)}
                                        }`,
  variables: {
  }
});

export const loadMoreAssetsQuery = (provider, group, offset) => JSON.stringify({
  query: `{
                                          ${group}: ${queryFunc[group]?.(provider, offset)}
                                        }`,
  variables: {
  }
});


export const assetGroupHistoricalDataQuery = (assetIds, start, end) => JSON.stringify({
  query: `{
                                                    
                                                    ${assetIds.reduce((acc, id) => acc + ` ass_${id.replace(/-/g, "_")}: instrument { historicalData(asset: {id: "${id}"}, startDate: ${start}, endDate: ${end}, period: "1D"){ close, date }} `, "")} 
                                                  
                                              }`,
  variables: {
  }
});

export const instrumentAssetDetailsQuery = (country, symbol, assetId) => JSON.stringify({
  query: `query($country: String!, $symbol: String!, $assetId: String!){
                                                                        instrument {
                                                                              fundamentals(asset: {country: $country, symbol: $symbol}){
                                                                                  ask,
                                                                                  averageVolume3Month,
                                                                                  bid,
                                                                                  close,
                                                                                  currency,
                                                                                  date,
                                                                                  exchange, 
                                                                                  high,
                                                                                  last,
                                                                                  lastQuantity,
                                                                                  low,
                                                                                  open,
                                                                                  previousClose,
                                                                                  previousOpen,
                                                                                  price,
                                                                                  priceChange,
                                                                                  priceChangePercent,
                                                                                  symbol,
                                                                                  totalDailyVolume,
                                                                                  volume,
                                                                                  week52High,
                                                                                  week52Low,
                                                                                  
                                                                              }
                                                                              
                                                                              assets(condition: {country: $country, symbol: $symbol}, pagination: {limit: 1}){
                                                                                category,
                                                                                country,
                                                                                currency,
                                                                                description,
                                                                                exchange,
                                                                                imageUrl,
                                                                                industry,
                                                                                listdate,
                                                                                status,
                                                                                name, 
                                                                                providers { provider },
                                                                                id,
                                                                                sector,
                                                                                similar,
                                                                                symbol,
                                                                                tags,
                                                                                type,
                                                                                url,
                                                                                meta,
                                                                              }
                                                                              asset(assetId: $assetId) {
                                                                                assetDetail {
                                                                                  created, duration, fixedReturn, id, instrumentId, maturityDate, startDate, symbol, updated,endDate
                                                                                },
                                                                  }
                                                                            }                                                       
                                                                    }`,
  variables: {
    country,
    symbol: symbol?.toUpperCase(),
    assetId
  }
});


export const assetsBySymbolQuery = (country, symbols) => JSON.stringify({
  query: `{
                                                                          ${symbols.reduce((acc, symb) => acc + ` ${symb}: instrument { assets(condition: {country: "${country}", symbol: "${symb}"}, pagination: {limit: 1}){${ASSET_QUERY_FIELDS}}} `, "")} 
                                                                        }`,
  variables: {
  }
});

export const singleAssetHistoricalDataQuery = (id, start, end) => JSON.stringify({
  query: `query($id: String!, $start: String!, $end: String!){
                                                                          instrument {   
                                                                            historicalData(asset: {id: $id}, startDate: $start, endDate: $end) {
                                                                              close,date, high,low,open,
                                                                            }
                                                                          }
                                                                        }`,
  variables: {
    id, start, end
  }
});

export const trendingRecentAssetsQuery = assetIds => JSON.stringify({
  query: `{
                                                                ${assetIds.reduce((acc, id, i) => acc + ` asset_${i}: instrument { asset(assetId: "${id}"){${ASSET_QUERY_FIELDS}}} `, "")} 
                                                                marketInsight{
                                                                  mostActive(country: "US", limit: 10) {
                                                                        ${ASSET_QUERY_FIELDS}
                                                                        }
                                                                }
                                                            }`,
  variables: {}
});

export const searchAssetQuery = (provider, query) => JSON.stringify({
  query: `query($query: String!, $provider: String!){
                                                      instrument {
                                                        search(query: $query, condition:{status: ACTIVE, provider: $provider}, pagination: {limit: 40}) {
                                                          ${ASSET_QUERY_FIELDS}
                                                        }
                                                      }
                                                    }`,
  variables: {
    query,
    provider
  }
});

export const portfolioPerformanceAssetDetailsQuery = (country, symbol, accountId) => JSON.stringify({
  query: `query($country: String!, $symbol: String!, $accountId: String!){
                                                    portfolioPerformance {
                                                      performance(request: { account: {accountId: $accountId} asset: {symbol: $symbol}, pagination: {limit: 30}, end: ${getTodayInSeconds(true)}, start: ${getDaysBackInSeconds(2, true)}}) {
                                                        time
                                                        value
                                                      }
                                                    }
                                                    today: instrument {
                                                      historicalData(asset: {country: $country, symbol: $symbol}, startDate: ${getDaysBackInSeconds(1)}, endDate: ${getTodayInSeconds()}) {
                                                          close,date, high,low,open
                                                      }
                                                    }
                                                    otherDays: instrument {
                                                          historicalData(asset: {country: $country, symbol: $symbol}, startDate: ${getDaysBackInSeconds(366)}, endDate: ${getTodayInSeconds()}, period: "1D") {
                                                              close,date, high,low,open
                                                          }
                                                    }
                                                  }`,
  variables: {
    country,
    accountId,
    symbol: symbol?.toUpperCase()
  }
});

export const marketInsightAssetDetailsQuery = (symbol, country, subScribed) => JSON.stringify({
  query: `query($symbol: String!){
                                                    marketInsight {
                                                      newsByTicker(ticker: $symbol, limit: 20) {
                                                        title, imageUrl, dateTime, url, source
                                                      }
                                                      keyStatistics(ticker: $symbol) {
                                                        peRatio,ttmEPS,dividendYield, marketcap
                                                      }
                                                      quarterlyEarningsReport(ticker: $symbol) {
                                                        actualEPS,consensusEPS,fiscalPeriod,fiscalEndDate
                                                      }
                                                      analystRatingSummary(ticker: $symbol) {
                                                        buy,hold, rating, sell,strongBuy,strongSell, targetPrice 
                                                      }
                                                      institutionalOwnership(ticker: $symbol) {
                                                        reportedHolding
                                                        entityProperName
                                                        filingDate
                                                      }
                                                      fundOwnership(ticker: $symbol) {
                                                        reportedHolding
                                                        entityProperName
                                                        filingDate
                                                        reportDate
                                                      }
                                                      financials(ticker: $symbol) {
                                                        dividendsPerBasicCommonShare
                                                        marketCapitalization
                                                      }
                                                      bullsAndBears(ticker: $symbol)

                                                      ${subScribed ? `
                                                                    analystSummary(ticker: $symbol)
                                                                    analystReport(ticker: $symbol)
                                                                    analystRatings(ticker: $symbol)`
      :
      ""}

                                                    }
                                                }`,
  variables: {
    symbol: symbol?.toUpperCase()
  }
});

export const ordersAssetDetailsQuery = (symbol, accountId) => JSON.stringify({
  query: `query($symbol: String!, $accountId: String!){
                                                    orders {
                                                      ordersConditional(order: { symbol: $symbol, accountId: $accountId}, pagination: { limit: 20 }) {
                                                        created,
                                                        symbol,
                                                        side,
                                                        type,
                                                        currency,
                                                        country,
                                                        accountId,
                                                        total,
                                                        price,
                                                        amount,
                                                        id,
                                                        quantity,
                                                        status,
                                                        fees {
                                                          flat,reason
                                                        }
                                                      }
                                                  }                                                         
                                              }`,
  variables: {
    accountId,
    symbol: symbol?.toUpperCase()
  }
});