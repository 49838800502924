import axios from "axios";
import { orderHistoryAssetInfoQuery, orderHistoryQuery } from "./orderHistoryQuery";


/*****
 * 
 *  Order History Request
 */

export const fetchOrderHistorysReq = accountId => {
  return axios.post("/graphql", orderHistoryQuery(accountId) );
}

export const fetchOrderHistoryAssetInfoReq = assetIds => {
  return axios.post("/graphql", orderHistoryAssetInfoQuery(assetIds) );
}

export const cancelOrderReq = (accountId, orderId) => {
  return axios.post(`/orders/cancel/${orderId}?accountId=${accountId}`, {});
}



