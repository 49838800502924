
export const DEFAULT_WATCHLIST_IMAGE_URL = "/assets/media/image/highflyers.png";
export const FAVOURITES_WATCHLIST_IMAGE_URL = "/assets/media/image/favourites.png";
export const DEFAULT_ASSET_ICON = "/assets/media/svg/box.svg";
export const DEFAULT_PROFILE_PICTURE = "/assets/media/image/avatar.png";
export const DEFAULT_WATCHLIST_DESCRIPTION = "This is the default watchlist created for you.";
export const ASSET_QUERY_FIELDS = `currency, country, id, imageUrl, name, symbol, url, exchange, providers { provider },
                                    quote {
                                        priceChangePercent, price, priceChange, exchange
                                    },`; 
export const STATEMENT_DOWNLOAD_INFO = "Preparing your download";
export const PENDING_VERIFICATION = "Account verification pending";
export const COMPLETE_PROFILE = "Complete your profile";
export const DEFAULT_CATEGORY_IMAGE_LOGO = "/assets/media/image/category-logo.png";
export const DEFAULT_CATEGORY_IMAGE_URL = "/assets/media/image/category.png";
export const DEFAULT_ACTIVITY_IMAGE = "/assets/media/image/activity.png";
export const FEES =  {
                        "ngn": [
                            {
                                "id": "stocks-ngn",
                                "currency": "NGN",
                                "buy": {
                                "percent": 2.34875,
                                "max_fee": 0,
                                "min_fee": 0,
                                "flat": 4.2
                                },
                                "sell": {
                                "percent": 2.14875,
                                "max_fee": 0,
                                "min_fee": 0,
                                "flat": 4.2
                                }
                            },
                            {
                                "id": "stocks-usd",
                                "currency": "USD",
                                "symbol": "$",
                                "buy": {
                                "percent": 1.0,
                                "max_fee": 0,
                                "min_fee": 0,
                                "flat": 0.0
                                },
                                "sell": {
                                "percent": 1.0,
                                "max_fee": 0,
                                "min_fee": 0,
                                "flat": 0.0
                                }
                            }
                        ],
                        "usd": [

                        ]
                    };
 